.custom-toggle.dropdown-toggle {
    background-color: transparent !important;
    border: 1px solid #dcdcdc !important;
    color: #000 !important;
    text-align: left;
    position: relative;
}
.custom-toggle.dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
    text-align: right;
    right: 10px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: none;
}
.selectDropdown .dropdown-menu{
    width: 100%;
}