@import '../../../scss/mixins';
// a.btn.barlink.text-primary {
//   position: absolute;
//   left: 6px;
//   z-index: 9;
//   top: 13px;
// }
a.btn.barlink.text-primary {
  left: 20px;
  width: 35px;
  height: 35px;
  padding: 7px 9px;
  margin: 0 0 0 5px;
  transition: .3s all ease;
}
.headerSearch {
  left: 6px;
}
.ml-1.nameFont b {
  font-size: 14px;
  font-weight: 500;
  color: #202020;
}
.ml-1.nameFont p{
  font-size: 12px;
  font-weight: 400;
  // color: #fff;
}
.dropdown-item { 
  padding: 0.25rem 1rem !important;
}
.grayCls{
  color: #84818A;
}
.ml-1.nameFont {
  line-height: 18px;
}
.sidebar-brand.p-3.pt-4.text-left.pl-5 {
  // border-bottom: 1px solid #E8E8E8;
  padding: 0px !important;
  height: 72px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  padding-left: 50px !important;
}
i.fas.fa-bars{
  color: #000;
  font-size: 18px;
}
.main-navbar {
  height: 72px;
  padding-left: 195px;
  padding-top: 0px;
  padding-bottom: 0px;
  border-bottom: 1px solid #e8e8e8 !important;
}
.main-navbar.min-sidebar {
  padding-left: 12px;
}
.main-navbar .nav-link {
  padding: 10px;
}
.main-navbar .nav-link-user > img {
  width: 34px;
  height: 34px;
  border: 1px solid #efefef;
  object-fit: cover;
} 

.ml-1.nameFont b {
  font-size: 14px;
  font-weight: 500;
  // color: #fff !important;
}
input.Searchbar:focus {
  outline: none;
}
.headerSearch {
  position: relative;
  font-size: 14px;
  color: #000;
}
.headerSearch .Searchbar {
  background: #f9f9f9;
  border: none;
  padding: 10px 20px 10px 20px;
  border-radius: 8px;
  height: 32px;
  font-size: 14px;
  font-weight: 400;
}
.headerSearch > i {
  position: absolute;
  top: 50%;
  color: #84818a;
  transform: translateY(-50%);
  cursor: pointer;
}
.headerSearch > i.fa-search {
  left: 10px;
}
.headerSearch > i.fa-times {
  right: 30px;
}


.notification {
  color: #CACCCF;
  font-size: 27px;
  transform: rotate(29deg);
  margin-right: 22px;
  margin-top: 16px;
}

.reddot {
  background-color: red;
  padding: 2px 5px;
  border-radius: 14px;
  color: #ffff;
  font-size: 12px;
  font-weight: 600;
  position: absolute;
  top: -13px;
  right: -2px;
  border: 3px solid #ffff;
  transform: rotate(333deg);
}
.min-sidebar ~ div > .main-sidebar a.nav-link.hoverclass {
  padding: 8px;
  border-radius: 6px !important;
  margin-right: 10px !important;
  width: 41px;
  justify-content: center;

}


.min-sidebar ~ div > .main-sidebar a.col_side {
  padding: 8px;
  border-radius: 6px !important;
  margin-right: 10px !important;
  width: 41px;
  justify-content: center;
}
.min-sidebar ~ div > .main-sidebar a.col_side:hover {
  justify-content: center;
  margin-right: 0px !important;
}
.min-sidebar ~ div > .main-sidebar {
  width: 65px !important;
  min-width: 70px;
}
.min-sidebar ~ div > .main-sidebar .sidebar-brand {
  width: 0;
  visibility: hidden;
}
.min-sidebar ~ div > .main-sidebar .nav-link span {
  display: none;
}
.min-sidebar ~ div > .main-sidebar .nav-link.hoverclass {
  justify-content: center !important;
}
.min-sidebar ~ div > .main-sidebar i {
  margin-right: 0px !important;
}
.min-sidebar ~ div > .main-sidebar i.sidenv {
  display: block !important;
  margin-right: 0px !important;
}
.min-sidebar ~ div > main.main {
  width: calc(100% - 58px);
}
.min-sidebar ~ div > .main-sidebar {
  width: 65px;
  min-width: 70px;
}
.min-sidebar ~ div > .main-sidebar .sidebar-brand {
  width: 0;
  visibility: hidden;
}
.min-sidebar ~ div > .main-sidebar .submenu {
  display: block;
}
.min-sidebar ~ div > .main-sidebar a.col_side span {
  display: none;
}
.min-sidebar ~ div > .main-sidebar .card.card-body.sides {
  background-color: transparent;
}
.min-sidebar ~ div > main.main {
  width: calc(100% - 58px);
}
.descmaxw{
 max-width: max-content !important;
}
@media(max-width:480px){
  .main-headwidth{
    width: 75% !important;
  }
}