.main_login {
  background-image: url("../../../public/assets/img/login_Img.png");
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: cover;
}
input:-internal-autofill-selected {
  background-color: #fff !important;
  color: #a7a1a1 !important;
  border-color: #d2cece !important;
  border: 1px solid #d2cece !important;

  height: 40px;
}
.top_section {
  background-image: linear-gradient(to left, #5146a0, #0c8e99, #24a2db);
  padding: 10px;
  border-radius: 20px;
}

.right_side {
  background-color: #fff;
  height: 100%;
  padding: 30px;
  border-width: 0px;
  border-radius: 20px;
}
