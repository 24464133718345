.profileImage {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  border: 1px solid #efefef;
  object-fit: cover;
}

.profileImageLabel {
  margin: 0;
  position: relative;
}
.profileImageLabel > i {
  position: absolute;
  bottom: -3px;
  right: -3px;
}

.pprofile1 {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  background-color: #fff;
  border-radius: 12px;
  // border: 1px solid #e1e1e1;
}

.bg-light.rounded.py-2.fieldcls {
  background-color: #775da62b !important;
}

.ViewUser {
  color: #202020;
  font-weight: 500;
  font-size: 18px;
  line-height: 14px;
}
