@import "../../../scss/mixins";

.main-sidebar .nav .nav-item.active > .nav-link {
  background-color: #fff;
  color: #707070;
  font-size: 14px;
  font-weight: 400;
}

.disabled {
  pointer-events: none;
}

.sideicon {
  font-size: 12px !important;
  // color: #000 !important;
}

.card.card-body.sides {
  background: transparent;
}

.hoverclass:hover {
  background: #d7f5ff;
  color: #fff !important;
  font-size: 14px;
  font-weight: 400;
}

.main-sidebar .nav .nav-item .nav-link {
  color: #000 !important;
  // background: #ffffffd7;
  font-size: 14px;
  font-weight: 400;
  height: 36px;
  margin: 3px 0px;
  display: flex;
  margin: 3px 13px;
  border-radius: 4px;
  // border-left: 5px solid #fff;
  align-items: center;

  & i {
    // color: #fff;
    font-size: 14px;
  }
  & i.sidenv {
    display: none;
  }
}

.main-sidebar .nav .nav-item > .nav-link:hover,
.main-sidebar .nav .nav-item.active > .nav-link {
  // color: #000 !important;
  // background: #775DA626;
  color: #000 !important;
  background: #f2f2f2;
  font-size: 14px;
  font-weight: 400;
  // border-left: 5px solid #775DA6;
  display: flex;
  height: 36px;
  align-items: center;
  margin: 3px 13px;
  border-radius: 4px;

  & i {
    color: #605959 !important;
  }
}

a.col_side {
  color: #202020 !important;
  font-size: 14px;
  font-weight: 300;
  height: 40px;
  display: flex;
  margin: 3px 14px;
  border-radius: 4px;
  align-items: center;
  padding: 0px 15px;
  border-radius: 4px;
  justify-content: space-between;
  width: auto;
}
a.col_side:hover {
  color: #000 !important;
  background: #f2f2f2;
  font-size: 14px;
  font-weight: 300;
  display: flex;
  border-radius: 4px;
  height: 40px;
  margin: 3px 14px;
  border-radius: 4px;
  align-items: center;
  padding: 0px 15px;
  text-decoration: none;
  & i {
    color: #000;
  }
  &.card.card-body.sides {
    border: none;
    visibility: hidden;
  }
}

.logocls {
  width: 100%;
  max-width: 185px !important;
}

.hoverclass {
  &.nobg {
    background: none !important;
  }
}

.submenu {
  display: none;
}

.active > .submenu {
  display: block;
}

.submenu > * > .hoverclass {
  padding-left: 25px;
}

.submenu.child2 > * > .hoverclass {
  padding-left: 35px;
}
span.side_head {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  // color: #fff;
}
.sidees span {
  font-weight: 400;
  white-space: nowrap;
}
.card.card-body.sides {
  // margin:6px;
  padding: 0px 0px;
}
.card.card-body.sides li.nav-item.active a span {
  color: #000 !important;
  font-weight: 400 !important;
}

.card.card-body.sides {
  border: none;
}
.card.card-body.sides li a span {
  // color: #fff !important;
  white-space: nowrap;
  // font-weight: 100;
  font-weight: 400;
  font-size: 14px;
  max-width: 165px;
}
span.side_icon {
  position: relative;
  right: -10px;
  top: 3px;
  color: #000;
}

.col_side div i.material-icons.mr-2 {
  font-size: 18px;
}
.collapsed div i.material-icons.mr-2 {
  // color: #fff !important;
  font-size: 18px;
}

a.col_side.collapsess.collapsed i.material-icons.mr-2 {
  color: #898989 !important;
  font-size: 18px;
}
.logo-width img {
  width: 50px;
}

.logo-width.pointer {
  position: absolute;
  left: 5px;
  z-index: 99;
  margin: 0;
  top: 5px;
  padding: 1px;
}
.main-head .headerSearch > i.fa-search {
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  left: unset !important;
}
.main-sidebar ul {
  // margin: 36px 0 0 0 !important;
  padding: 0 0 15px 0;
}
.sidees {
  display: flex;
  align-items: center;
}
nav.navbar.navbar-expand-lg.main-navbar.min-sidebar .logo-width.pointer {
  // left: 3px;
}

span.side_icon i.material-icons {
  font-size: 17px;
  line-height: 22px;
}
.portal-page.profile-tabs {
  margin: 44px 0 0 0;
}
.padrow {
  padding: 15px 20px;
}
.colbtn {
  color: #202020 !important;
  font-size: 14px;
  font-weight: 300;
  // height: 40px;
  display: flex;
  margin: 3px 14px;
  border-radius: 4px;
  align-items: center;
  padding: 7px 15px;
  justify-content: space-between;
  width: auto;
  border: none;
  background: none;
  font-weight: 400;
  white-space: nowrap;
}
.colbtn:hover {
  color: #000 !important;
  background: #f2f2f2;
  border-radius: 4px;
}
// .colbtn.collapsed .sideicon1{
//     display: block;
// }
// .sideicon1{
//     display: none;
// }
// .sideicon2{
//     display: block;
// }
// .colbtn.collapsed .sideicon2{
//     display: none;
// }
.sideTwo , .sideOne{
    margin-left: 0 !important;
}
.col_side[aria-expanded="false"] .sideTwo {
    display: none;
  }
// .colbtn.collapsed {
//     .sideOne {
//         display: none;
//       }
// }

//   .sideOne {
//     display: block;
//   }
.col_side[aria-expanded="true"] .sideTwo {
    display: block;
}
.col_side[aria-expanded="true"] .sideOne {
    display: none;
}

