.dropdownOverlap{
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top:0;
  display: none;
  z-index: 999;
}
.daterangeDropdown{
  z-index: 999;
}

.rdrDateDisplayWrapper{
  display: none;
}
.cld_btn button {
  width: 47%;
}
.new_dropdown {
width: fit-content !important;
}
.dropdown_width {
transform: translate3d(-130px, 60px, 0px) !important;
}

.datepickerdropdown-menu{
  width: fit-content;
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0 0 7px #00000038;
  background-color: #fff;
  position: absolute;
  right: 0;
  top: 100%;
}

.datepickerdropdown{
  position: relative;
  z-index: 99;
}