@import "./mixins";

// Colors
@import "./colors";

.cursor-pointer{
  cursor: pointer;
}

.rate-div {
  position: relative;
  display: inline-block;
  margin-bottom: 10px;

  > label {
    display: inline-block;
    float: right;
    font-size: 0;
    line-height: normal;
    cursor: pointer;
    color: lightgrey;
    margin-bottom: 0 !important;
    padding: 0 4px;

    &:before {
      font-size: 30px;
      content: '★';
    }

    &:hover {
      color: #e8262d;
      ~ label {
        color: #e8262d;
      }
    }
  }

  > input {
    display: none;
    &:checked ~ label {
      color: gold;
      text-shadow: none;
    }
  }

  > input:checked + label:hover,
  > input:checked + label:hover ~ label,
  > input:checked ~ label:hover,
  > input:checked ~ label:hover ~ label,
  > label:hover ~ input:checked ~ label {
    color: #e8262d;
    text-shadow: none;
  }
}

.ql-container{
  height: 250px !important;
  overflow: auto;
}

body {
  font-family: "Poppins", sans-serif;
}

.dropdown-menu:empty {
  display: none !important;
}

.data_row.overtime {
  // background-color: #fbc5c8 !important;
  background-color: #4a4c9f2e !important;
  td {
    background-color: transparent;
  }
}

.badges_status {
  background: #237ab9;
  color: #fff;
  padding: 2px 13px;
  border-radius: 46px;
  margin-right: 2rem;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.badges_reg {
  background: #fff;
  color: #000;
  padding: 2px 13px;
  border-radius: 46px;
  margin-right: 2rem;
}
.d-flex.bg_rotter.justify-content-between {
  background: #3278b91c;
  padding: 10px;
  border-radius: 15px;
  margin-bottom: 1rem;
}
img.delete_icon {
  width: 80px;
}
.bg_hover .dropdown-item.active,
.bg_hover .dropdown-item:active {
  background-color: #494f9f !important;
  border-radius: 8px;
  color: #ffffff !important;
  padding: 0.25rem 1rem;
  text-decoration: none;
}

.bg_hover .dropdown-item.active,
.bg_hover .dropdown-item:active {
  text-decoration: none;
  background-color: #494f9f !important;
  color: #fff !important;
}


.paginationWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
}
.paginationWrapper > span {
  font-size: 13px;
  color: #84818a;
}
.chip {
  background:#494f9feb !important;
}
.alignTopDate div.ant-picker-input {
  top: -12px;
}
button:focus:not(:focus-visible) {
  outline: 0;
  box-shadow: none !important;
}
.react-pagination-js-default ul {
  display: flex;
  align-items: center;
  column-gap: 10px;
}
.react-pagination-js-default ul > li {
  margin: 0 !important;
  font-size: 13px !important;
  padding: 7px 13px !important;
}
.react-pagination-js-default ul > li:first-child {
  padding: 0 !important;
}
.react-pagination-js-default ul > li:first-child:not(.disabled) {
  color: #1f7bf4 !important;
  padding: 7px 13px !important;
}
.react-pagination-js-default ul > li:first-child::after {
  content: "< ";
}
.react-pagination-js-default ul > li:first-child a {
  display: none;
}
.react-pagination-js-default ul > li:last-child {
  padding: 0 !important;
}
.react-pagination-js-default ul > li:last-child:not(.disabled) {
  color: #1f7bf4 !important;
  padding: 7px 13px !important;
}
.react-pagination-js-default ul > li:last-child::after {
  content: " >";
}
.react-pagination-js-default ul > li:last-child a {
  display: none;
}
.react-pagination-js-default ul > li.page {
  border-color: #000 0 !important;
  background-color: transparent !important;
  box-shadow: none !important;
  font-weight: 400 !important;
  padding: 7px 13px !important;
}
.react-pagination-js-default ul > li.is-active {
  font-weight: 500 !important;
  background-color: #494f9f !important;
  border-color: #494f9f !important;
  box-shadow: none !important;
  text-shadow: none !important;
  color: #ffffff !important;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}
// label {
//   font-size: 14px;
//   font-weight: 500;
//   text-transform: capitalize;
//   color: #202020;
// }
.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 10px;
  padding-left: 5px;
  align-items: center;
}

.removeBg {
  padding: 8px 18px !important;
  border-radius: 5px;
}
// :where(.css-dev-only-do-not-override-14wwjjs).ant-picker-focused {
//   border-color: #e9e9e9 !important;
// }
// :where(.css-dev-only-do-not-override-14wwjjs).ant-picker:hover,
// :where(.css-dev-only-do-not-override-14wwjjs).ant-picker-focused {
//   border-color: #e4e4e4 !important;
// }
// :where(.css-dev-only-do-not-override-14wwjjs).ant-picker-range
//   .ant-picker-active-bar {
//   background: #494f9f !important;
// }
// :where(.css-dev-only-do-not-override-14wwjjs).ant-picker-dropdown
//   .ant-picker-cell-in-view.ant-picker-cell-selected
//   .ant-picker-cell-inner,
// :where(.css-dev-only-do-not-override-14wwjjs).ant-picker-dropdown
//   .ant-picker-cell-in-view.ant-picker-cell-range-start
//   .ant-picker-cell-inner,
// :where(.css-dev-only-do-not-override-14wwjjs).ant-picker-dropdown
//   .ant-picker-cell-in-view.ant-picker-cell-range-end
//   .ant-picker-cell-inner {
//   color: #fff;
//   background: #494f9f !important;
// }
// :where(.css-dev-only-do-not-override-14wwjjs).ant-picker-dropdown
//   .ant-picker-cell-in-view.ant-picker-cell-in-range::before {
//   background: #494f9f !important;
// }
// :where(.css-dev-only-do-not-override-14wwjjs).ant-picker-dropdown
//   .ant-picker-cell-in-view.ant-picker-cell-range-start:not(
//     .ant-picker-cell-range-start-single
//   )::before,
// :where(.css-dev-only-do-not-override-14wwjjs).ant-picker-dropdown
//   .ant-picker-cell-in-view.ant-picker-cell-range-end:not(
//     .ant-picker-cell-range-end-single
//   )::before {
//   background: #494f9f !important;
// }
// :where(.css-dev-only-do-not-override-14wwjjs).ant-picker-dropdown
//   .ant-picker-cell-in-view.ant-picker-cell-today
//   .ant-picker-cell-inner::before {
//   border: 1px solid #494f9f !important;
// }
i.fa {
  cursor: pointer;
}
.dateRangePicker {
  width: 100% !important;
  border-radius: 8px;
  padding: 21px 10px;
  border: 1px solid #dcdcdc;
  height: calc(1.5em + 0.75rem + 2px);
}

.optionListContainer {
  background: #fff;
  border-radius: 4px;
  margin-top: 1px;
  position: absolute;
  width: 220px;
  z-index: 9999999;
}
.ccttype {
  border-radius: 8px;
}

.react-datepicker__close-icon::after {
  cursor: pointer;
  background-color: red;
}

.text-truncate {
  width: 250px;
  white-space: nowrap;
  resize: horizontal;
}

.star {
  color: red !important;
}

.action_icons {
  display: flex;
}

.edit_icon {
  align-items: flex-start;
  display: flex;
  justify-content: flex-start;
  border-radius: 5px;
  margin-right: 6px;
}
.edit_icon:hover {
  text-decoration: none !important;
}

.mainarea {
  background: #f6f6f6;
  padding: 40px;
  height: calc(100vh - 72px) !important;
  overflow: auto;
  // width: calc(100% - 58px);
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #dcdcdc !important;
  outline: 0;
  box-shadow: none !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
// i.fa.fa-eye {
//   color: #494f9f;
// }

i.material-icons.edit {
  font-size: 15px;
  font-weight: 600;
  color: #494f9f;
}
i.material-icons.delete {
  font-size: 15px;
  font-weight: 600;
  color: red;
}
i.fa.fa-arrow-left {
  color: #000;
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f9f9f9 !important;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;

  white-space: nowrap;
}

.table_data {
  border-top: none !important;
  border-bottom: none !important;
  font-size: 14px;
  font-weight: 500 !important;
  color: #25282b !important;
  text-transform: capitalize;
}

.inputWrapper {
  position: relative;
}
.inputWrapper .fa {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  // color: #d2cece;
  font-size: 12px;
}

.modal-title,
.dropdown-item {
  text-transform: capitalize;
}

input:-ms-input-placeholder {
  color: #000 !important;
  opacity: 1 !important;
}

.loaderDiv {
  z-index: 99999;
  backdrop-filter: blur(2px);
  align-items: center;
  justify-content: center;
  display: flex;
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
}
.dropdown-menu.shadow.bg_hover.show {
  border: none;
}
.css-13cymwt-control {
  border-radius: 40px;
}
.searchWrapper {
  border-radius: 40px;
}
.pageLoader {
  max-width: 50px;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #a0a4a8;
  opacity: 1;
  font-size: 14px;
  font-weight: 400;
  /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #a0a4a8;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #a0a4a8;
}

main.main {
  background-color: #f6f6f6 !important;
}

// Loader start
img.loaderlogo {
  max-width: 50px;
  // animation: rotation 3s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}
span.bg_bages {
  background: #52b1ff21;
  margin-right: 10px;
  padding: 7px 10px;
  border-radius: 50px;
  color: #56a0dc;
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 10px !important;
  display: inline-block;
}
// Loader end

.btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn-outline-primary {
  color: #494f9f;
  border-color: #494f9f;
}

a {
  cursor: pointer;
}

.taableWrapper {
  max-height: calc(100vh - 250px);
}

button.btn.btn-primary.loginclass {
  border-radius: 44px;
  width: 100%;
  height: 49px;
  font-size: 14px;
  font-weight: 600;
  padding: 5px 15px;
  background: #494f9f !important;
}

.login-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
.login-wrapper > .container {
  max-width: 500px;
}
.login-wrapper > .container .login-logo {
  width: 100%;
  max-width: 150px;
}

.bginput {
  // border: 1px solid #e8e8e8 !important;
  border-radius: 8px !important;
  border: 1px solid #e8e8e8;
  height: 45px;
}
input.form-control.mb-0.bginput.changes {
  background-color: #fff !important;
  border-color: #d2cece !important;
  border: 1px solid #d2cece !important;
  border-radius: 38px !important;
  height: 40px;
}

.empty-box {
  padding: 15px;
  text-align: center;
}
.empty-box .icon-box {
  padding: 15px 30px;
  font-size: 26px;
  margin-bottom: 10px;
}

// h3.text-left.lgtext {
//   font-weight: 600;
//   font-size: 24px;
//   color: #444444;
// }

.paraclss {
  font-weight: 400;
  font-size: 20px;
  line-height: 29px;
  color: #775da6 !important;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #f6f6f6;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #f6f6f6;
}

.modal {
  overflow-y: auto;
}
.modal .card-body,
.modal .modal-boldy {
  max-height: calc(100vh - 200px);
  overflow-y: auto;
}
.upload-btn {
  position: relative;
}
.upload-btn > input {
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.pagination > li {
  padding: 2px;
}
.pagination > li a {
  padding: 7px 10px;
  display: inline-block;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  font-size: 14px;
  min-width: 40px;
  text-align: center;
  color: #000;
  text-decoration: none;
}
.pagination > li.active a {
  color: #fff;
}
.pagination > li.disabled a {
  border-color: #e8e8e8;
  color: #e8e8e8;
}

.shine {
  background: #eee;
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  background-size: 200% 100%;
  animation: 1.5s shine linear infinite;
  min-height: 20px;
  border-radius: 5px;
}

.shine.shineCard {
  height: 250px;
}

strong.shine {
  display: block;
  margin-bottom: 14px;
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}

.table td,
.table th {
  padding: 0.75rem;
  vertical-align: middle;
}

// edit icon css
i.fa.fa-pencil-alt.edit {
  margin: 0px 15px;
  font-size: 18px;
  color: #7561a2;
  font-weight: 700;
}
i.fa.fa-trash {
  color: #c02e2e;
  font-size: 18px;
  font-weight: 700;
}

.buttons_Section {
  display: flex;
  align-items: center;
  margin-right: 12px;
}
.cardList {
  margin-top: 2rem;
}
a.btn.btn-primary.mr-2 {
  white-space: nowrap;
}
.form-control {
  // border-radius: 40px !important;
  border: 1px solid #dcdcdc;
}
select.form-control.types {
  height: 43px;
  margin-right: 12px;
  width: 100%;
  max-width: 200px;
}
.new_cards {
  background: #fff;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  max-height: 300px;
}
input.searchbar.mr-2 {
  padding: 8px;
  border-color: #cdd4da;
  border-radius: 5px;
  outline: none;
  border: 1px solid #cdd4da;
  height: 43px;
}
// profile changes
input.searchbar.mr-2:focus {
  box-shadow: 0 3px 10px 0 17%;
  border-color: #7561a2;
}
a.btn.btn-primary.profiles {
  background-color: #fff !important;
  border: 1px solid #c2c2c2 !important;
  height: auto;
  color: #2020209c !important;
  border-radius: 12px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
i.material-icons.prob {
  font-size: 27px;
  margin: 0px 7px;
  color: #0000004a;
}
label.btn.btn-primary.delete.ml-3 {
  background-color: #fff !important;
  border-radius: 10px;
  color: #7561a2 !important;
  font-size: 14px !important;
  font-weight: 500;
}

label.btn.btn-primary.edit.ml-3 {
  border-radius: 10px;
  font-weight: 600;
  font-size: 14px;
}
.btn.btn-secondary.discard {
  background-color: #fff !important;
  border: 1px solid #dcdcdc !important;
  color: #202020 !important;
  font-size: 14px !important;
  height: 40px;

  font-weight: 500;
}
a.discard {
  text-decoration: none;
  color: #202020 !important;
  font-size: 14px;
  font-weight: 500;
}
a.discard :hover {
  color: #202020 !important;
  font-size: 14px;
  font-weight: 500;
}
.btn.btn-primary.reset {
  background-color: #fff !important;
  border: 1px solid #dcdcdc !important;
  color: #202020 !important;
  font-size: 14px !important;
  // height: 43px;
  border-radius: 10px;
}
button.btn.btn-primary.edit.ml-3 {
  border-radius: 10px;
  font-weight: 600;
  font-size: 14px;
}
.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-color-type: #202020 !important;
  --bs-table-bg-type: rgb(249 249 249 / 100%) !important;
}

a.btn.barlink.text-primary.active {
  outline: none;
  border: none;
}
a.btn.barlink.text-primary:active {
  border: none;
}
.barlink:focus {
  box-shadow: none !important;
}
p.profile_data {
  margin-bottom: 0px !important;
  font-size: 14px;
  font-weight: 400;
  color: #5a5a5a;
}
.col-md-12.inputFlex {
  line-height: 30px;
}
.highlightOption {
  background: #7561a2 !important;
  color: #fff;
}
.multiSelectContainer li:hover {
  background: #7561a2 !important;
  color: #fff;
}

// New CSS

.settings-inner {
  border: 0;
}
.settings-inner li {
  list-style: none;
  margin: 0 8px;
}

.settings-inner .nav-link,
.settings-inner.nav-tabs .nav-link.active {
  color: #fff;
  font-size: 13px;
  background: #494f9f;
  border: 0;
}
.settings-inner.nav-tabs .nav-link {
  border: 0;
  border-radius: 0.25rem;
  font-size: 13px;
}
.nav-pills .nav-link {
  text-align: left;
  color: #000;
}
.nav-pills .nav-link.active,
.settings-inner.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #494f9f !important;
}
.custom-tab {
  width: 70%;
  height: 100vh;
}
.setting-inner {
  width: 30%;
  padding: 10px;
  background: #fff;
  border-radius: 5px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.content-inner h4 {
  font-size: 18px;
  font-weight: 600;
}
// .d-flex.justify-content-between.align-items-center {
//   flex-wrap: wrap;
// }

input[type="checkbox"] {
  margin-right: 10px;
}

// .title-head {
//   background: #f2f2f2;
//   padding: 11px 30px;
//   border-bottom: 1px solid #cbcbcbef;
//   border-top-left-radius: 12px;
//   border-top-right-radius: 12px;
// }
.imagethumbWrapper {
  position: relative;
  display: inline-block;
}
.imagethumbWrapper img {
  width: 100px;
  border-radius: 50px;
  height: 100px;
  object-fit: cover;
}
img.uploadimage {
  width: 100px;
  border-radius: 50%;
  border: 4px solid #efefef;
  object-fit: cover;
  height: 100px;
  object-fit: cover;
}
.common-padding {
  padding: 30px;
}
.user-back {
  display: flex;
  align-items: center;
}
.user-back a {
  margin: 0 10px 0 0;
}
.user-back a i {
  font-size: 16px;
}
.edit-bg i {
  background: #ccc;
  padding: 8px 9px;
  border-radius: 4px;
  color: #ffffff !important;
}

.custom-table.table thead th {
  vertical-align: bottom;
  border-bottom: 0;
  // background: #f2f2f2;
  text-align: center;
}
.custom-table td {
  text-align: center;
}

.user-name-color {
  font-size: 12px;
  color: #222;
}
button#dropdownMenuButton {
  display: flex;
  align-items: center;
}
.main {
  // padding-top: 2rem !important;
  // padding-left: 30px !important;
  // padding-right: 30px !important;
  // margin-left: auto !important;
  // padding-bottom: 1rem !important;
}
// Media Queries

@media only screen and (min-width: 320px) and (max-width: 527px) {
  .dropdown.addDropdown.region_status {
    margin-top: 0px;
  }
  .icons_tab {
    margin-top: 15px;
  }
  .dropdown.addDropdown.chnagesg {
    margin-top: 15px;
  }

  .main-navbar.min-sidebar {
    // padding-left: 40px;
    display: flex;
  }
  .main-sidebar {
    position: absolute;

    z-index: 998;
  }

  .sidebar-brand.p-3.pt-4.text-left.pl-5 {
    display: none;
  }
  .min-sidebar ~ div > main.main {
    // width: 100% !important;
  }

  // proffile dropdown
  .dropdown.ml-auto {
    top: -30px;
    right: -16px;
  }

  // .headerSearch {
  //   top: 20px !important;
  // }

  // main p-age

  .d-flex.justify-content-between.align-items-end.mb-3 {
    display: block !important;
  }
  .nav-tabs {
    border-radius: 3px !important;
    height: 40px !important;
    // background: none !important;
    // border: none !important;
    margin-top: 1rem;
  }
  .rangetabs {
    border-bottom: 0 !important;
    border: 1px solid #cbc1c1 !important;
    border-radius: 4px !important;
    background: #fff !important;
    // width: 100% !important;
    height: auto !important;
  }
  .tbl-shadow {
    height: auto !important;
  }
  .icons_tab {
    margin: 0px !important;
  }
  .nav-tabs {
    border-bottom: 1px solid #ffffff;
  }
  article.d-flex {
    display: block !important;
    // margin-top: 1rem !important;
  }
  .artmt {
    margin-top: -1rem !important;
  }
  .new_cards {
    height: 100% !important;
    margin-bottom: 0rem !important;
    max-height: 500px !important;
    //  height: 100%;
    //   margin-bottom: 0rem !important;
  }
  li.list_names {
    text-align: left !important;
  }
  .user_proff {
    display: block !important;
  }

  // category css
  .d-flex.justify-content-between.align-items-end.mb-0 {
    display: block !important;
  }
  select.form-control.types {
    margin-top: 13px;
    width: 100%;
  }
  // i.fa.fa-eye {
  //   color: #775da6;
  // }
  .d-flex.justify-content-between.mb-3 {
    // display: block !important;
  }
  // .d-flex.justify-content-between {
  //   display: block !important;
  // }

  button.btn.btn-primary.btnreset {
    margin-top: 15px;
  }

  // dashboard

  h2.mb-1.mainHeading_cls {
    font-size: 20px;
    font-weight: 600;
  }
  p.grayCls.mb-0 {
    font-size: 12px;
    font-weight: 200;
    color: #898989;
    width: auto !important;
  }

  // a.btn.barlink.text-primary {
  //   left: 19px;
  //   width: 35px;
  //   height: 35px;
  //   padding: 7px 9px;
  //   top: 17px;
  // }
  form.headerSearch.ml-3 {
    width: 100% !important;
    margin-left: 9px !important;
  }
  p.mb-0 {
    white-space: nowrap;
    width: 180px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  // .ml-3 {
  //   width: 200px;
  // }
  img.listIc {
    height: 40px;
    width: 40px;
  }
  .borderBox.d-flex.align-items-center.justify-content-between {
    padding: 0px 15px;
  }
  .timeBoxs p {
    font-size: 14px;
    color: #484848;
    line-height: 17px;
  }

  .headerSearch .Searchbar {
    width: 100%;
    max-width: 130px;
  }

  // dashboard responsive
  .graph_range {
    display: block;
  }
  p.view_graph {
    margin-top: 18px;
  }

  // admin user
  .d-flex.justify-content-between.align-items-center {
    // display: block !important;
  }
  span {
    font-size: 14px;
  }
  span.side_head {
    font-size: 14px;
  }

  article.d-flex a,
  button {
    margin-bottom: 1rem !important;
  }
  button.btn.btn-primary.dropdown-toggle.removeBg {
    margin-bottom: 0px !important;
  }

  .table_section {
    margin-top: 10px !important;
  }
  .mr-2.phBottomSpace {
    margin-bottom: 10px;
  }
  a.btn.btn-primary.profiles {
    width: fit-content;
  }
  button.btn.btn-primary.mr-2.export {
    margin-left: 15px;
  }
  .hedding {
    font-size: 21px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 441px) {
  button.btn.btn-primary.mr-2.exports {
    margin-top: 15px;
  }
  h5.heading_holidays {
    margin-top: 30px !important;
  }
}
@media screen and (min-width: 320px) and (max-width: 767px) {
  .min-sidebar ~ div > .main-sidebar a.col_side:hover {
    justify-content: center;
    margin: 8px 14px !important;
  }
  .headerSearch {
    display: none;
  }
  .min-sidebar ~ div > .main-sidebar {
    // display: block !important;
    // max-width: 250px;
    // min-width: 250px;
    // width: 100%;
    // min-height: 100vh;
    // margin-top: -72px;
    // border-right: 1px solid #e8e8e8;
    width: 65px;
    min-width: 72px;
  }
  .sidees {
    display: flex;
  }
  // .logo-width , .hidebt{
  //   display: none;
  // }
  .min-sidebar ~ div > .main-sidebar a.nav-link.hoverclass {
    padding: 9px 16px;
    border-radius: 12px !important;
    margin-right: 10px !important;
    display: flex !important;
    justify-content: left !important;
    width: auto !important;
  }
  .min-sidebar ~ div > .main-sidebar a.col_side {
    padding: 9px 16px;
    border-radius: 12px !important;
    margin-right: 10px !important;
    display: flex !important;
    justify-content: space-between !important;
    width: auto !important;
  }
  .min-sidebar ~ div > .main-sidebar .nav-link span {
    // display: block;
  }
  .min-sidebar ~ div > .main-sidebar a.col_side span {
    display: flex;
    font-size: 15px;
    font-weight: 300;
    color: #202020;
    display: none;
  }
  .min-sidebar ~ div > .main-sidebar i {
    margin-right: 8px !important;
  }
  .main-sidebar {
    // display: none;
  }
  .main-sidebar .nav .nav-item .nav-link i.sidenv {
    display: none !important;
  }
}

@media (max-width: 790px) {
  // dashboard
  //   .datepickerdropdown-menu {
  //     width: -webkit-fill-available;
  // }
  // .datepickerdropdown-menu .rdrMonthAndYearWrapper {
  //   align-items: center;
  //   height: 50px;
  //   padding-top: 0px;
  //   flex-wrap: wrap;
  //   margin-bottom: 50px;
  // }
  .datepickerdropdown-menu .rdrMonths {
    display: flex;
    flex-direction: column;
  }
  //  .datepickerdropdown-menu .rdrMonth {
  //   width: min-content;
  // }
  // .datepickerdropdown-menu .rdrDays {
  //   display: flex;
  //   flex-wrap: wrap;
  // }
}
@media screen and(min-width:601px) and (max-width: 695px) {
  .icons_tab {
    margin-top: 0px;
  }
}

@media only screen and (min-width: 528px) and (max-width: 600px) {
  .dropdown.addDropdown.region_status {
    margin-top: 0px;
  }
  .dropdown.addDropdown.chnagesg {
    margin-top: 15px;
  }
  .dropdown.addDropdown.chnages_status {
    margin-top: 15px;
  }

  .dropdown.addDropdown {
    margin-top: 15px;
  }
  button.btn.btn-primary.mr-2.export {
    margin-left: 15px;
  }
  .dropdown.addDropdown.chnagesg {
    margin-top: 15px;
  }
  .icons_tab {
    margin-top: 15px;
  }
  .d-flex.justify-content-between.align-items-center {
    /* flex-wrap: wrap; */
    // display: block !important;
  }
  article.d-flex {
    display: block !important;
    // margin-top: 1rem !important;
  }
  .artmt {
    margin-top: -1rem !important;
  }
  .min-sidebar ~ div > .main-sidebar {
    // display: none !important;
  }
  .main-navbar.min-sidebar {
    // padding-left: 60px;
    display: flex;
  }
  .main-sidebar {
    position: absolute;
    top: 143px;
    z-index: 998;
  }

  .sidebar-brand.p-3.pt-4.text-left.pl-5 {
    display: none;
  }
  .min-sidebar ~ div > main.main {
    // width: 100% !important;
  }

  // category css
  .d-flex.justify-content-between.align-items-end.mb-0 {
    display: block !important;
  }
  article.d-flex {
    margin-top: 1rem;
  }
  .artmt {
    margin-top: -1rem !important;
  }
  .d-flex.justify-content-between.mb-3 {
    // display: block !important;
  }

  p.mb-0 {
    font-size: 13px;
  }
  img.listIc {
    height: 40px;
    width: 40px;
  }
  form.headerSearch.ml-3 {
    width: auto;
    max-width: 130px;
  }
  // .ml-3 {
  //   width: 300px;
  // }
  span {
    font-size: 14px;
  }
  span.side_head {
    font-size: 14px;
  }
  article.d-flex {
    margin-top: 1rem;
    flex-wrap: wrap;
  }
  .reversearticle {
    flex-wrap: nowrap !important;
    margin: 0 !important;
  }
  .artmt {
    margin-top: -1rem !important;
  }
  button#dropdownMenuButtonstatusDropdown\ reset {
    margin-top: 15px;
  }

  .dropdown.addDropdown.chnages {
    margin-top: 15px;
  }
  .dropdown.addDropdown.chnagest {
    margin-top: 15px;
  }
}
@media (max-width: 530px) {
  .main-navbar {
    padding-left: 68px !important;
  }
}

@media only screen and (min-width: 601px) and (max-width: 615px) {
  .reversearticle {
    flex-wrap: nowrap !important;
    margin: 0 !important;
  }
  article.d-flex {
    flex-wrap: wrap !important;
    display: flex;
  }
}
@media only screen and (min-width: 767px) and (max-width: 990px) {
  .main-wrapper main.main {
    // width: 100% !important;
    // width: calc(100% - 250px) !important;
    padding-left: 0px;
    padding-right: 0px;
  }
}
@media only screen and (min-width: 601px) and (max-width: 990px) {
  .min-sidebar ~ div > .main-sidebar {
    // display: none !important;
  }
  .d-flex.justify-content-between.align-items-center {
    /* flex-wrap: wrap; */
    // display: block !important;
  }
  .main-navbar.min-sidebar {
    // padding-left: 60px;
    display: flex;
  }
  .main-sidebar {
    // position: absolute;
    // top: 143px;
    z-index: 998;
    position: relative;
    width: 100%;
    min-height: 100vh;
    top: 72px;
  }

  .sidebar-brand.p-3.pt-4.text-left.pl-5 {
    display: none;
  }
  .min-sidebar ~ div > main.main {
    // width: 100% !important;
  }

  .d-flex.justify-content-between.align-items-end.mb-0 {
    display: block !important;
  }
  article.d-flex {
    // margin-top: 1rem;
    flex-wrap: wrap;
  }
  .reversearticle {
    flex-wrap: nowrap !important;
    margin: 0 !important;
  }
  .artmt {
    margin-top: -1rem !important;
  }

  .d-flex.justify-content-between.align-items-end.mb-3 {
    display: block !important;
  }

  .selectDropdown::placeholder {
    width: 100px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .selectDD {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .col-12.col-sm-12.col-md-12.col-lg-6.cls {
    margin-top: 15px;
  }
  span {
    font-size: 14px;
  }
  span.side_head {
    font-size: 14px;
  }
  .dropdown.addDropdown.chnagest {
    margin-left: 15px;
  }
}
@media screen and (min-width: 601px) and (max-width: 609px) {
  .dropdown.addDropdown.region_currency {
    margin-top: 15px;
  }
}
@media only screen and (min-width: 601px) and (max-width: 621px) {
  .dropdown.addDropdown.chnages {
    margin-top: 15px !important;
  }

  article.d-flex {
    flex-wrap: wrap !important;
    display: flex;
  }
  // .reversearticle{
  //   flex-wrap: nowrap !important;
  //   margin: 0 !important;
  // }
  .reversearticleagin {
    flex-wrap: wrap !important;
    gap: 10px 0px;
  }
  .dropdown.addDropdown.chnagest_name {
    margin-top: 13px;
  }
}
@media (min-width: 768px) {
  .main-navbar.min-sidebar ~ div > .main-sidebar {
    display: block !important;
  }
}
@media (min-width: 1180px) {
  .sales_section {
    max-height: 125px;
  }
  .sales_section_stats {
    max-height: 238px;
  }
}
@media only screen and (min-width: 991px) and (max-width: 1000px) {
  .dropdown.addDropdown.chnagesg {
    margin-top: 0px;
  }

  .min-sidebar ~ div > .main-sidebar {
    // display: none !important;
  }
  .main-navbar.min-sidebar {
    // padding-left: 60px;
    display: flex;
  }
  .main-sidebar {
    position: absolute;
    top: 143px;
    z-index: 998;
  }

  .sidebar-brand.p-3.pt-4.text-left.pl-5 {
    display: none;
  }
  .min-sidebar ~ div > main.main {
    // width: 100% !important;
    width: calc(100% - 58px) !important;
    margin: 0 50px !important;
  }

  .d-flex.justify-content-between.align-items-end.mb-0 {
    display: block !important;
  }
  article.d-flex {
    margin-top: 1rem;
  }
  .artmt {
    margin-top: -1rem !important;
  }
  .main-wrapper main.main {
    width: 100% !important;
  }
  .d-flex.justify-content-between.align-items-end.mb-3 {
    display: block !important;
  }

  .selectDropdown::placeholder {
    width: 100px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  span {
    font-size: 14px;
  }
  span.side_head {
    font-size: 14px;
  }
  .dropdown.addDropdown.chnagest {
    margin-left: 10px !important;
  }
}

@media only screen and (min-width: 1001px) and (max-width: 1199px) {
  .dropdown.addDropdown.chnagest {
    margin-left: 10px !important;
  }
  // article.d-flex {
  //   margin-top: 1rem !important;
  // }
}

@media only screen and (min-width: 1200px) and (max-width: 1400px) {
  .dropdown.addDropdown.chnageses {
    margin-top: 12px;
  }
  .dropdown.addDropdown.chnageset {
    margin-top: 12px;
  }

  .sales_section {
    padding: 15px;
  }
  .graph_section {
    padding: 20px;
  }
  .dropdown.addDropdown.chnagest {
    margin-left: 10px !important;
  }
}

@media (max-width: 960px) {
  .wrapit {
    flex-wrap: wrap;
    gap: 5px 10px;
  }
  .another-row .equal-width-50 {
    width: 30% !important;
  }
}
@media screen and (max-width: 630px) {
  .dropdown.addDropdown.features.mr-2 {
    margin-top: 14px;
  }
  .inputFlex-main {
    flex-wrap: wrap;
  }
  .inputFlex {
    flex-wrap: wrap;
  }
  .reverseprofile .pprofile1 {
    padding: 0;
  }
}

@media screen and (max-width: 908px) {
  .dropdown.addDropdown.city_status {
    margin-top: 14px;
  }
}
@media screen and (max-width: 900px) {
  .dropdown.addDropdown.chnageses {
    margin-top: 15px;
  }
  .dropdown.addDropdown.city_status {
    margin-top: 14px;
  }
}
@media (max-width: 768px) {
  .taableWrapper {
    max-height: calc(100vh - 300px);
  }
}
@media only screen and (max-width: 767px) {
  .main-sidebar.d-md-block {
    position: fixed !important;
    left: -1px !important;
    z-index: 999 !important;
    // margin-top: 0px !important;
    top: 144px;
  }
  .main {
    padding-top: 2rem !important;
    padding-left: 30px !important;
    padding-right: 30px !important;
    margin-left: auto !important;
    padding-bottom: 2rem !important;
  }
  .text-right {
    margin-bottom: 15px !important;
  }
  .roleTable tr td {
    // width: 150px !important;
    // max-width: 150px !important;
    min-width: 150px !important;
  }
  .col-md-9.head_cls {
    margin-bottom: 20px;
  }
  .reversearticle {
    flex-wrap: nowrap !important;
    margin: 0 !important;
  }
  .artmt {
    margin-top: -1rem !important;
  }
  article.d-flex {
    display: flex !important;
    // margin-top: 1rem !important;
    flex-wrap: wrap;
  }

  .d-flex.justify-content-between.align-items-center {
    /* flex-wrap: wrap; */
    // display: block !important;
  }

  .another-row .phView {
    justify-content: start !important;
  }
  .equal-width-50 {
    // width: 100% !important;
  }
  .dropdown.addDropdown.equal-width-50.col-sm-6.mt-0 {
    padding: 0 !important;
    margin-top: 9px !important;
  }
}
@media screen and (max-width: 766px) {
  .dropdown.addDropdown.chnages_date {
    margin-top: 15px;
  }
}
@media screen and (max-width: 759px) {
  .dropdown.addDropdown.region_status {
    margin-top: 15px;
  }
}
@media screen and (max-width: 751px) {
  .dropdown.addDropdown.chnagess {
    margin-top: 15px;
  }
  .dropdown.addDropdown.chnageses {
    margin-top: 15px;
  }
}
@media screen and (max-width: 748px) {
  .dropdown.addDropdown.city_region {
    margin-top: 14px;
  }
  .dropdown.addDropdown.city_status {
    margin-top: 14px;
  }
}
@media screen and (max-width: 738px) {
  .dropdown.addDropdown.chnageset {
    margin-top: 15px;
  }
}
@media (max-width: 600px) {
  .mainarea {
    padding: 25px !important;
  }
  .fiftyflex {
    flex-wrap: wrap;
    gap: 10px;
  }
  .fiftyflex div {
    width: 100% !important;
  }
  .wrapphone {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 590px) {
  button.btn.btn-primary.btnreset {
    margin-top: 15px !important;
  }

  a.btn.btn-primary.btnreset {
    margin-top: 15px !important;
  }
}
@media screen and (max-width: 578px) {
  .dropdown.addDropdown.chnagesapp {
    margin-top: 15px;
  }
}
@media (max-width: 575px) {
  .equalmt2 {
    margin-top: 10px !important;
    padding: 0 !important;
  }
}
@media (max-width: 550px) {
  .another-row .equal-width-50 {
    width: 100% !important;
    margin-left: 0 !important;
  }
}
@media (min-width: 550px) and (max-width: 767px) {
  .another-row .equal-width-50 {
    width: 40% !important;
  }
}
@media (min-width: 992px) {

  .modal-body .mb-3 {
    white-space: no-wrap !important;
  }
}
.whites {
  white-space: no-wrap !important;
  font-size: 18px;
}
// css by Manjinder
.bg_img.main_signup {
  height: 100vh;
}
.center-img {
  height: 100vh;
}
.center-img .container {
  height: 100vh;
}
.center-img .row {
  justify-content: center;
  height: 100%;
  align-items: center;
}
.right_sidee {
  background-color: #f8f8f8;
  height: 100%;
  padding: 2rem 2rem;
  border-radius: 20px;
  border: 1px solid #3f559e;
}
.remeber {
  color: #898989;
  font-size: 12px;
}
.dark-btn {
  background: linear-gradient(
    100deg,
    #7177be -5.85%,
    #252c8e 109.55%
  ) !important;
  color: #fff;
  width: 100%;
  padding: 10px 8px;
}
.dark-btn:focus {
  background: linear-gradient(
    100deg,
    #7177be -5.85%,
    #252c8e 109.55%
  ) !important;
}
.dark-btn:hover {
  border: 1px solid #3f559e;
  color: #fff;
  background: transparent;
}
h3 {
  font-size: 32px;
  line-height: 40px;
  font-weight: 600;
}
.login_input {
  padding: 8px 10px 8px 10px;
  background: #f0edff !important;
  border: 1px solid transparent !important;
  border-radius: 12px;
}
.login_input:focus {
  padding: 8px 10px 8px 10px;
  background: #f0edff !important;
}
.login_input {
  background: #f0edff !important;
  border: 1px solid transparent !important;
}
.login_heading {
  font-weight: 600;
  font-size: 22px;
  line-height: 22px;
  color: #434343;
}
.text_login {
  color: #757575;
  letter-spacing: 0.75px;
  margin: 7px 0 30px 0;
  text-align: center;
  font-size: 15px;
}
.text_login2 {
  font-size: 12px;
  color: #040404;
}


p.sub_heading {
  font-size: 14px;
  font-weight: 500;
  color: #202020;
  margin-bottom: 0px !important;
}
.sub_description {
  font-size: 14px;
  font-weight: 400;
  color: #202020;
  margin-bottom: 0px !important;
}

@media (max-width: 480px) {
  // dashboard
  .datepickerdropdown-menu {
    width: -webkit-fill-available;
  }
  .datepickerdropdown-menu .rdrMonthAndYearWrapper {
    align-items: center;
    height: 50px;
    padding-top: 0px;
    flex-wrap: wrap;
    margin-bottom: 50px;
  }

  .datepickerdropdown-menu .rdrMonth {
    width: min-content;
  }
  .datepickerdropdown-menu .rdrDays {
    display: flex;
    flex-wrap: wrap;
  }
  .mainarea {
    padding: 15px !important;
  }
  .main-headwidth {
    width: 75% !important;
  }

  .text_login {
    font-size: 10px;
  }
  .paginationWrapper {
    flex-direction: column;
    gap: 10px;
  }
  //   .portal-tabs .nav-item {
  //     font-size: 10px !important;
  // }
  .portal-tabs li.nav-item span {
    margin-left: 0px !important;
    //  font-size: 10px !important;
  }
  .main-head {
    // width: 70% !important;
  }
  .main-navbar {
    padding-left: 22px !important;
  }
  .main-navbar .nav-link {
    padding-left: 0 !important;
  }
  .a.btn.barlink.text-primary {
    margin-left: 0 !important;
  }
  .sales_section_stats .Amount_sales {
    padding-top: 12px !important;
    font-size: 15px;
  }
  .sales_section_stats {
    min-height: 100px;
  }
}
// slider css
.range-slider {
  height: 3px !important;
}
.range-slider .range-slider__thumb {
  width: 10px !important;
  height: 10px !important;
  background: #494f9f !important;
}
.range-slider .range-slider__range {
  background: #494f9f !important;
}
.range-slider__range {
  height: 4px !important;
}
.range_price {
  color: #666666;
  font-size: 12px;
  font-weight: 600;
  margin-top: 5px;
}

// carriers page css
.carries_search input.form-control {
  padding-right: 22px !important;
}
.carries_search i.fa.fa-times {
  position: relative;
  left: -18px;
  font-size: 12px;
  color: gray;
}

.btn-secondary {
  border: 1px solid #474e9c !important;
  color: #474e9c !important;
}
.btn-secondary:hover {
  background: #474e9c;
  color: #fff !important;
}
.user-back i {
  color: #343434 !important;
  font-size: 15px;
}
.inputFlex-main {
  display: flex;
}
.inputFlex-main label {
  width: 200px;
  font-size: 13px;
  font-weight: 600;
}
.head-dropdown .dropdown-item {
  font-size: 14px;
}
.action-icons-main .edit_icon {
  justify-content: center;
  align-items: center;
}
.pprofile1.edit-page-common {
  background: transparent;
}
// .edit-page-common .title-head {
//   background: #f2f2f2;
//   padding: 21px 30px;
//   border-bottom: 1px solid #cbcbcbef;
//   border-top-left-radius: 12px;
//   border-top-right-radius: 12px;
// }
.white-bg-main {
  background: #fff;

  border: 1px solid #efefef;
  border-radius: 5px;
}
.white-head {
  background: #e0e0e0;
  padding: 15px 20px;
  border-bottom: 1px solid #efefef;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.white-head h5 {
  margin: 0;
}
.white-bg-main > .row {
  padding: 15px 20px;
}
.badge.badge-success.common-badge {
  display: block;
  width: 60px;
  padding: 5px 9px;
  background: #494f9f;
  font-size: 11px;
  font-weight: 400;
  cursor: pointer;
}
.ellipses {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 170px;
}
.board-view {
  padding: 6px 15px;
  border-radius: 6px;
  border: 1px solid #efefef;
  width: 48%;
  height: 45px;
  align-items: center;
}
.boards-details {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.second-row {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  background: #fff;
  margin: 20px 0 0 0;
}

.multiselect-custom .multiSelectContainer input {
  color: #222;
  margin: 3px 0;
}
.multiselect-custom .chip {
  background: #f2f2f2;
  color: #504c4c;
}
.col-md-6.mb-3.view-flex,
.col-md-12.mb-3.view-flex {
  display: flex !important;
}

.view-flex .profiledetailscls {
  background: transparent;
  padding: 0;
  border-radius: 0;
  font-size: 14px;
  // height: 40px;
  height: auto;
  border: 0;
  width: 100%;
  line-break: anywhere;
}

.main-row.common-padding label {
  margin-bottom: 5px;
}
// .destination-address{
//   width: 400px;
// }
.table_data i {
  margin-left: 2px;
  font-size: 11px;
  // color: #ab2c2c;
  color: #4a4c9f;
}

.active .custom-toggle-btn .react-switch-bg {
  height: 20px !important;
  width: 40px !important;
  background: #494f9f !important;
}
.deactive .custom-toggle-btn .react-switch-bg {
  height: 20px !important;
  width: 40px !important;
  background: #d7c8c8 !important;
}
.react-switch-handle {
  width: 12px !important;
  height: 12px !important;
  top: 4px !important;
  transform: translateX(4px) !important;
}
.deactive .react-switch-handle {
  transform: translateX(24px) !important;
}
.custom-toggle-btn .react-switch-bg svg {
  display: none;
}
span.custom-toggle-btn {
  height: 100%;
  display: flex;
}
.action_icons .edit_icon {
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 4px;
  margin-right: 10px;

  transition: 0.3s;
  cursor: pointer;
}

.custom-exclamation {
  font-size: 60px;
  padding: 30px;
  background: #494f9f;
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 20px auto;
  color: #fff;
  border-radius: 50%;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.highlightOption {
  background: #f8f9fa !important;
  color: #484343 !important;
  padding-top: 5px;
  padding-bottom: 5px;
}
.multiSelectContainer li {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  font-size: 14px;
}
.multiSelectContainer li:hover {
  background: #f8f9fa !important;
  color: #484343 !important;
  padding-top: 5px;
  padding-bottom: 5px;
}
.multiselect-custom {
  background: #fff;
}
ul.optionContainer {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}
.multiselect-custom .multiselect-custom .chip {
  background: #f2f2f2;
  color: #484343;
  padding: 4px 8px;
  line-height: 17px;
}
.multiselect-custom .closeIcon {
  cursor: pointer;
  float: right;
  height: 13px;
  margin-left: 5px;
  width: 13px;
  filter: brightness(0.3);
}
.multiselect-custom .icon_down_dir {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 14px;
  filter: opacity(0.5);
}
.dropdown-item.active,
.dropdown-item:active {
  background-color: #f2f2f2 !important;
  color: #000;
}
.dropdown-item {
  font-size: 14px;
}
i.material-icons.delete {
  font-size: 15px;
  font-weight: 600;
  color: #b74747 !important;
}
.action_icons .edit_icon .fa.fa-eye {
  color: #878181;
}
i.fa.fa-times.colorred {
  color: #b74747 !important;
}
i.fa.fa-check.color-green {
  color: #494f9f;
}
.react-pagination-js-default ul > li:last-child:not(.disabled) {
  color: #656566 !important;
  padding: 7px 13px !important;
}
li.nav-item svg {
  font-size: 16px;
  font-weight: lighter;
  stroke: black;
}
li.nav-item span {
  margin-left: 10px;
}
.main-head .dropdown-toggle::after {
  display: none;
}
.username-head {
  text-align: right;
}
.username-head b {
  font-size: 14px;
  font-weight: 500;
}
.username-head p {
  font-size: 12px;
  margin: 0;
  line-height: 15px;
  color: #746c6c;
  letter-spacing: 0.53px;
}
.profile-dropDOwn .dropdown-item svg {
  font-size: 14px;
}
.profile-dropDOwn .dropdown-item {
  font-size: 13px;
  color: #000;
  display: flex;
  align-items: center;
}
.equal-width-70 {
  width: 30%;
}
.equal-width-50 {
  width: 39%;
}
.equal-width-50 .btn {
  // width: 100%;
}
// .filterFlex .dropdown-menu {
//   width: 100%;
// }
.equal-width-50 .blck-border-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  border: 1px solid #d5d5d5 !important;
  height: 38px;
  color: #554f4f !important;
}
.commonborder {
  border: 1px solid #d5d5d5 !important;
  background: #fff;
  width: 220px;
  color: #554f4f !important;
  font-size: 13px;
}
.equal-width-50 .btn.btn-primary.dropdown-toggle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.another-row {
  width: 90%;
}
.another-row .phView {
  width: 100%;
  justify-content: flex-end;
}
.another-row .equal-width-50 {
  width: 22%;
}

// gouarv css copy  width frontend

// .profile-tabs-left {
//   background: #ecf1f6;
//   border-radius: 7px 0 0 7px;
//   height: 100%;
//   border-right: 1px solid #e9e7e7;
// }
// .profile-tabs .set_pills .nav-link.active, .profile-tabs  .set_pills .show > .nav-link {
//   color: #2e2e2e;
//   background-color: #ffffff !important;
//   border-radius: 0 !important;
//   text-align: left;
//   font-size: 14px;
// }

// .set_pills .nav-link.active, .set_pills .show > .nav-link {
//   background: none;
//   color: #4ba5d9;
//   box-shadow: none;
//   border-bottom: 3px solid #4ba5d9;
//   border-radius: 0px;
// }
// .profile-tabs .nav-link {
//   color: #51617a;
//   border-radius: 0 !important;
//   text-align: left;
//   font-size: 14px;
//   white-space: nowrap;
//   font-weight: 600;
//   border-bottom: 1px solid #e9e7e7;
//   padding: 10px;
// }

// .box-shadow-prop > .row {
//   box-shadow: 0 0 15px #3f576e4d;
//   border-radius: 7px;
// }

input#statusDropdown_input {
  width: 100px !important;
}
.upload-img-icon {
  position: absolute;
  bottom: 0;
  right: 0;
}
.upload-img-icon .btn {
  margin: 0;
  background: #efefef;
  padding: 2px;
  border-radius: 50px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.upload-img-icon svg {
  color: #969595;
}
.imagethumbWrapper .upload-img-icon .fa-times {
  position: unset;
  background-color: transparent;
  color: #969595;
}
.pprofile1.edit-page-common .common-padding {
  padding: 30px 0 !important;
}
.col-md-4.view-flex {
  display: flex;
}
.bids-count-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.bids-count-info label {
  font-size: 14px;
  font-weight: 500;
}
.table thead th {
  background: #efefef;
  font-weight: 500;
  border: 0;
}
.stops-address h5 {
  border: 0.5px dashed #3c3939;
}

// .carrier-detail {
//   box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
//   padding: 15px;
//   border: 1px solid #e2e2e2;
//   position: relative;
// }
// .carrier-detail h4 {
//   font-size: 13px;
//   display: flex;

//   font-weight: 500;
// }
// .carrier-detail h4  span{
//   font-weight: 600;
//   margin-left: 5px;
// }
// h4.name-carrier {
//   margin: 29px 0 10px 0;
// }
// .carrier-detail > span {
//   position: absolute;
//   right: 10px;
//   top: 10px;
// }
// .carrier-detail p {
//   color: #655757;
//   margin: 5px 0 17px 0;
//   font-size: 13px;
// }
// .accepted-rejected {
//   display: flex;
//   justify-content: space-around;
// }
// .accepted-rejected span{
//   padding: 11px 14px;
// }
// .accepted-rejected {
//   margin: 23px 0 0 0;
// }

.bid-modal .modal-dialog {
  // max-width: 600px;
  min-width: 62vw;
}
.timer {
  background: #eaeaea;
  padding: 4px 10px;
  font-size: 12px;
  font-weight: 500;
  border-radius: 5px;
  text-align: center;
  color: #302e2e;
  width: 100%;
  max-width: 160px;
  height: 30px;
}
.timer svg {
  min-height: 13px !important;
  min-width: 13px !important;
  max-height: 13px !important;
  max-width: 13px !important;
}
span.best-bidder img {
  width: 18px;
  margin: 0 0 0 2px;
}
.bid-modal .table {
  border: 1px solid #efefef;
}
.portal-tabs-view li {
  // width: 50%;
  text-align: center;
}
.origin-address .profiledetailscls {
  width: 181px;
  height: unset;
  display: flex;
}
span.see-more {
  font-size: 12px;
  line-height: 22px !important;
  color: #3c559b;
  text-align: end;
  height: 14px !important;
  cursor: pointer;
  display: inline;
}

.read-more-new.profiledetailscls {
  background: transparent;
  padding: 0;
  border-radius: 0;
  font-size: 14px;
  height: auto;
  border: 0;

}

.box-shadow-prop > .row {
  box-shadow: 0 0 15px #3f576e4d;
  border-radius: 7px;
}
.profile-tabs-left {
  background: #ecf1f6;
  border-radius: 7px 0 0 7px;
  height: 100%;
  border-right: 1px solid #e9e7e7;
}
.profile-tabs .nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #2e2e2e;
  background-color: #ffffff !important;
  border-radius: 0 !important;

  text-align: left;
  font-size: 14px;
}
.profile-tabs .nav-link {
  color: #51617a;
  border-radius: 0 !important;
  text-align: left;
  font-size: 14px;
  border-bottom: 1px solid #e9e7e7;
  padding: 10px;
}
.profile-tabs .form-group {
  display: flex;
  margin: 0;
}
.profile-tabs label {
  width: 190px;
  font-size: 14px;
  margin: 0;
}
.profile-tabs .profileImage-main {
  width: 72px;
  height: 72px;
  border-radius: 50%;
  object-fit: cover;
}
.profile-tabs .pprofile1 {
  border: 0;
}
.edit-profiles {
  background: #ccc !important;
  border-radius: 4px;
  border: 0 !important;
  padding: 4px;
  width: 32px;
  height: 30px;
  text-align: center;
}
.edit-profiles i {
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.edit-profiles i:before {
  color: #fff;
}
.profile-tabs .form-group p {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.2px;
}

.pprofile1 {
  padding: 21px 22px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  background-color: #fff;
  border-radius: 12px;
}
span.add-notes {
  font-size: 13px;
  color: #786f6f;
  font-weight: 500;
}
.no-data {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 13px;
  background: #fff;
  padding: 20px;
}
.no-data img {
  width: 120px;
}
.table {
  margin-bottom: 0;
}
.bid-tabs li {
  width: 50%;
  text-align: center;
}
.bid-tabs li button {
  width: 100%;
}
.bid-tabs {
  border: 0;
  width: 250px;
  border-radius: 4px;
  padding: 3px;
  background: #e5e5e5 !important;
  margin: 0 auto 20px auto;
}
.bid-tabs.portal-tabs .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  border-radius: 4px;
  width: 100%;
}

.bid-tabs.portal-tabs .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active svg {
  stroke: #fff;
}
.addLoadNew {
  display: flex;
  align-items: center;
}
.addLoadNew .btn {
  width: 50% !important;
}
.delete-btn-red i {
  font-size: 13px;
  padding: 12px;
  background: #f2f2f2;
  border: 1px solid #efefef;
  border-radius: 4px;
  color: #bd3131 !important;
}
.bid-modal span.badge {
  width: 84px;
}
.notFound {
  display: block;
  padding: 16px;
  text-align: center;
  font-size: 13px;
}
.profile_img_side {
  position: relative;
  text-align: center;
  width: 72px !important;
  height: 72px;
}
.profileImage {
  width: 72px !important;
  height: 72px !important;
  object-fit: cover;
}

.profile_img_side input {
  font-size: 14px !important;
}
.profile-tabs .new_images {
  width: unset;
  font-size: 14px;
  margin: 0;
  position: absolute;
  bottom: 0;
  right: 0;
}
.uploader {
  margin: 0;
  background-color: #fff;
  padding: 3px;
  border-radius: 40px;
  box-shadow: 0px 0px 4px 0px #47569d;
  display: flex;
  align-items: center;

  color: #47569d;
  cursor: pointer;
}
span.uploader i {
  font-size: 14px;
}
.inputWrapper i {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 12px;
  color: #ccc;
}
.rdtPicker td.rdtActive,
.rdtPicker td.rdtActive:hover {
  background-color: #474e9c;

  border-radius: 3px;
}
@media (max-width: 1199px) {
  .addLoadNew .btn {
    font-size: 14px;
    line-height: 24px;
  }
}

.modal-body .small {
  margin-bottom: 20px;
  background: #efefef;
  padding: 10px;
  border-radius: 4px;
}

.bid-modal .modal-content .tab-content {
  width: 100%;
  overflow: auto;
}

.bid-modal .modal-content {
  width: 100% !important;
}
.rdrDayNumber span:focus-visible {
  border: 0;
  outline: 0 !important;
}

.rdrStartEdge {
  background: #494f9f !important;
}

.rdrInRange {
  background: #494f9f !important;
}

.modal-body .small {
  margin-bottom: 20px;
  background: #efefef;
  padding: 10px;
  border-radius: 4px;
}
.rdrDayNumber::after {
  text-decoration: none;
}
span.rdrEndEdge {
  background: #474e9c;
}
p.view_graph {
  display: flex;
  color: #474e9c;
}
span.arrowss {
  color: #494f9f !important;
  height: unset;
  margin: 0 4px !important;
  width: unset !important;
  position: unset !important;
}
span.arrowss i {
  font-size: 19px;
  line-height: 19px;
}
.rejected span {
  background: #b8161678;
  padding: 4px 10px;
  border-radius: 3px;
  text-transform: capitalize;
  font-size: 12px;
  width: 80px;
  display: block;
  text-align: center;
}
.accepted span {
  background: #3c559b82;
  padding: 4px 10px;
  border-radius: 3px;
  text-transform: capitalize;
  font-size: 12px;
  width: 80px;
  display: block;
  text-align: center;
}
.pending span {
  background: #ffa50033;
  padding: 4px 10px;
  border-radius: 3px;
  color: #000;
  text-transform: capitalize;
  font-size: 12px;
  width: 80px;
  display: block;
  text-align: center;
}
.logo-hidden {
  width: 50px;
}

.mobile-logo {
  display: none;
}
.min-sidebar .mobile-logo {
  display: block;
  width: 50px;
}
.tricon i {
  padding: 5px;
  border-radius: 20px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  color: gray;
  font-size: 10px;
  border: 1px solid #80808029;
}
.rs-steps.rs-steps-vertical {
  width: 100% !important;
}
.desp {
  background: #775da626;
  padding: 5px 10px;
  margin: 0;
}
.desp p {
  margin: 0;
}
.prange2 {
  margin-top: 2px;
  font-size: 10px;
}
.nowrapflex {
  flex-wrap: nowrap !important;
}

.zfonts {
  font-size: 8px;
  padding-left: 3px;
  line-height: 13px;
}
.afont {
  position: relative;
  top: 0px;
  margin-top: -5px;
  font-size: 8px !important;
  line-height: 13px !important;
}
.zfont {
  // position: relative;
  bottom: -5px;
  font-size: 8px !important;
  line-height: 13px !important;
}
.arrfont {
  font-size: 15px;
  color: #000000c4;
  height: 19px;
  line-height: 19px;
  width: 15px;
  margin-left: -1px;
}
.rs-steps-item-icon-wrapper {
  border-color: #494f9f !important;
  background-color: #494f9f !important;
  color: #fff !important;
}
.rs-steps-item-title::after {
  border-color: #494f9f !important;
}
.rs-steps-item-tail {
  border-color: #494f9f !important;
}
nav.navfix {
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 999;
}

.socrollingcol {
  position: fixed;
  max-height: calc(100vh - 71px);
  overflow: auto;
  overflow-x: hidden;
  max-width: 250px;
}
.prodflex {
  display: flex;
  overflow: auto;
  width: 100%;
}
.prodflex .profiledetailscls {
  background: transparent;
  height: auto;
}
.destspan {
  width: 95px;
  display: block;
}
.tableficon i {
  padding: 5px;
  border-radius: 20px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  color: gray;
  font-size: 10px;
  border: 1px solid #80808029;
}
.docdown {
  position: absolute;
  top: 67%;
  left: 59%;
}
.awardic svg {
  color: gray;
}
.markertext {
  color: #3a7c3a !important;
}
.awarded span {
  background: #3a7c3a21;
  padding: 4px 10px;
  border-radius: 3px;
  text-transform: capitalize;
  font-size: 12px;
  width: 80px;
  display: block;
  text-align: center;
}
.statusdivs .delivered {
  color: #3a7c3a !important;
}
.statusdivs .pending {
  color: #b81616d9;
}
.statusdivs .in_transit {
  color: #06065e;
}
.statusdivs .pickedup {
  color: #181856;
}
.pdfimg {
  width: 100px;
  object-fit: cover;
}
.onedit {
  font-size: 14px;
}
.custom-exclamationtwo {
  width: 75px !important;
  height: 75px !important;
}

.amospan {
  min-width: 30px;
}
.viewed {
  color: #494f9f !important;
}
.edit-bg2 {
  background: #494f9f !important ;
  padding: 8px 9px;
  border-radius: 4px;
  color: #ffffff !important;
  font-size: 15px !important;
}

.startwrap .react-datepicker-wrapper {
  width: 100%;
}
.equalmt2 {
  margin-top: 0;
}
.reversearticle {
  flex-wrap: nowrap !important;
  margin: 0 !important;
}
@media (max-width: 600px) {
  .reversearticleagin {
    flex-wrap: wrap !important;
    gap: 10px 0px;
  }
  .view-flex {
    flex-wrap: wrap;
  }
}
.wraperpadding .searchWrapper {
  padding: 0 5px !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.accepted_user {
  color: green;
}
.rejected_user {
  color: rgb(255, 0, 0);
}
.view-flex {
  gap: 15px;
}
.rejectedbid {
  color: #ff0000cf !important ;
  font-size: 12px;
  font-weight: 500;
  // padding: 3px 15px 3px 15px;
  border-radius: 5px;
  // background: #ff00001a;
  // width: 92px;
  display: inline-block;
  text-align: center;
}
.pendingbid {
  color: orange !important;
  font-size: 12px;
  font-weight: 500;
  // padding: 6px 15px 3px 15px;
  // background: #ffa5004d;
  // width: 92px;

  text-align: center;
}
.awardedbid {
  color: #4a4c9f !important;
  font-size: 12px;
  font-weight: 500;
  // padding: 6px 15px 3px 15px;

  // background: #4a4c9f4d;

  text-align: center;
}
.acceptedbid {
  color: green !important;
  font-size: 12px;
  font-weight: 500;
  // padding: 6px 15px 3px 15px;
  // background: #0080001f;
  // width: 92px;
}
.table_dats p {
  margin: 0 !important;
}
.actiondbtn {
  background: none;
  border: none;
  color: gray;
}
.actiondbtn::after {
  display: none !important;
}
.dotsmore {
  font-size: 20px !important;
}
.actiondbtnparent {
  position: relative;
}
.actiondbtnparent ul.dropdown-menu.show {
  min-width: 80px;
  padding: 3px 5px !important;
  position: absolute !important;
  left: -15px !important;
  top: -10px !important;
}
.actiondbtnparent a {
  padding: 3px 3px !important;
}
.actiondbtnparent span {
  font-size: 12px;
}

.sales_section_stats {
  .subArc path {
    fill: #ebebeb;
  }
  .value-text text {
    font-size: 16px !important;
    fill: #5146a0 !important;
    text-shadow: none !important;
    text-anchor: middle;
  }
  .tick-value text {
    font-size: 12px !important;
  }
  .grafana-gauge {
    // position: absolute;
    text-align: center;
    margin-top: -12px;
    top: 40px;
  }
}
.sales_section_top {
  min-height: 100px;
}
.sales_section_top .Amount_sales {
  font-size: 18px;
  font-weight: 500;
  line-height: 32px;
  margin-bottom: 0px !important;
  padding-top: 8px !important;
  text-align: center;
}
.doublep {
  font-size: 12px;
}


// .ant-tooltip-open{
//   font-size: 5px !important;
// }
@media (min-width: 992px) {
  .navbar-expand-lg .navbar-toggler {
    display: block !important;
  }
}
.firstptag {
  margin: 0 !important;
  line-height: 18px;
}
.subptag {
  margin: 0;
  font-size: 13px;
  text-align: center;
}
.enterslabel {
  font-size: 14px;
}
.sales_section.sales_section_top,
.sales_section_stats,
.enterderstats {
  box-shadow: 4px 5px 0px 0px #494f9f6b !important;
  // border-radius: 0;
}
@media (min-width: 992px) and (max-width: 1142px) {
  .sales_section_stats .value-text text {
    font-size: 12px !important;
  }
}
@media (max-width: 991px) {
  .demodiv {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 878px) {
  .sales_section_stats .value-text text {
    font-size: 12px !important;
  }
}
@media (max-width: 670px) {
  .intoflex {
    flex-wrap: wrap;
  }
  .intoflex .form-control {
    width: 100% !important;
  }
}
@media (max-width: 410px) {
  .sales_section_stats .value-text text {
    font-size: 12px !important;
  }
  .sales_section_stats .grafana-gauge {
    margin-top: 0px;
  }
}
.react_desc {
  .ql-container.ql-snow {
    min-height: 150px;
  }
}
.hone_handle h1 {
  font-size: 13px !important;
}
.dropdown-menu-right {
  right: 0;
  left: auto !important;
}
img.connect_imgs {
  width: 100%;
  height: 78px;
  object-fit: contain;
}
.connect_title {
  font-size: 16px !important;
  max-width: 187px;
  line-height: 22px;
}
span.truncate-css {
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}
.modal-backdrop.fade.show {
  display: none;
}

span.ico-clr {
  background: #eaeaea;
  color: #4a4c9f;
  padding: 2px 12px;
  font-size: 16px;
  width: 35px;
  height: 30px;
  border-radius: 4px;
}
.text-heads {
  color: #5146a0;
  font-size: 17px !important;
  font-weight: 700;
}
svg.mr-2.ico-modal {
  background: #eaeaea;
  font-size: 27px;
  padding: 5px;
  border-radius: 4px;
}

.not-tracked-m .rs-steps-item-icon-wrapper > .rs-steps-item-icon {

  color: #ffffff !important                                                                                                                                                                               ;
}
.No-trcak {
  color: #f11717db !important;
}
.custom-add-column{
  font-size: 15px !important;
  color: #5a5755 !important;
}
.custom-datepicker {
  border-radius: 8px;
  border: 1px solid #ccc;
  padding: 10px;
  font-size: 16px;
  width: 100%;
  cursor: pointer;
}

/* Hide the default month & year text from the header */
.react-datepicker__current-month {
  display: none !important;
}

.react-datepicker__header {
  background-color: white !important;
  border-bottom: none !important;
  padding-top: 5px !important;
}

/* Style the dropdown menus */
.react-datepicker__month-dropdown,
.react-datepicker__year-dropdown {
  background: white !important;
  border-radius: 8px !important;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1) !important;
  padding: 5px !important;
}

.react-datepicker__year-dropdown-container,
.react-datepicker__month-dropdown-container {
  display: flex !important;
  justify-content: center !important;
}

.react-datepicker__year-option:hover,
.react-datepicker__month-option:hover {
  background-color: #f0f0f0 !important;
  border-radius: 4px !important;
}
.react-datepicker__header__dropdown, .react-datepicker__header__dropdown--select{
  display:flex !important;
  padding: 5px;
  margin-left: 15px !important;
}