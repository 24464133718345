.searchForm{
    max-width: 300px;
}

.sidebar_new {
    display: flex;
    flex-direction: row;
    height: 100vh;
    position: fixed;
    transition: .8s ease;
    z-index: 9;

}
.inner_wraping {
    background: #494f9f;
    display: flex;
    justify-content: center;
    padding: 10px;
    text-align: center;
    width: 50px;
    overflow-y: auto;
    overflow-x: hidden;
}
.big_sideswrap {
    background: #f5f5f5;
    border-right: 1px solid hsla(0,0%,81%,.973);
    position: relative;
    width: 230px;
}
.mai_data_full {
    background: #00218f0a;
    margin-left: auto;
    overflow-y: scroll;
    padding-left: 0;
    padding-right: 0;
    padding-top: 59px;
    transition: .4s ease;
    width: calc(100% - 280px);
}
.logo-img-n img {
    width: 30px !important;

    margin: 0 auto 10px auto;
    width: auto;
}
.small_sdies {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
}
.small_sdies .ulclasssides {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    list-style: none;
    padding: 0;
}
.ulclasssides li a{
    color: #fff;
}
.ulclasssides li a svg {
    width: 15px;
    height: 15px;
}
.ulclasssides li {
    cursor: pointer;
    margin: 5px 2px 13px;
}
.pulse_profile {
    animation: shadow-pulse 1s infinite;
    background: #1e90ff99;
    border-radius: 50%;
    color: #fff;
    font: 12px/100px Barlow Semi Condensed,sans-serif;
    height: 30px;
    letter-spacing: 1px;
    margin-left: 0;
    text-transform: uppercase;
    width: 30px;
}
img.rounded-circles {
    border-radius: 100%;
    width: 25px;
    height: 25px;
    margin: 0 auto;
    display: block;
}
.profile-img .dropdown-toggle::after{
    display: none;
}
.profile-img .dropdown {
    display: inline-block;
    margin-left: 0;
    padding: 0;
}
.main-wrappers {
    background-color: #fff;
    flex-direction: row;
}
.hegiht100 {
    height: 100vh;
}
.big_sideswrap {
    background: #f5f5f5;
    border-right: 1px solid hsla(0,0%,81%,.973);
    position: relative;
    width: 230px;
}
.headerlogo.collpase_data {
    border-bottom: 1px solid hsla(0,0%,81%,.973);
    height: 60px;
    padding: 15px;
}
.half_sides {
    margin-left: 50px;
    overflow-y: scroll;
    width: 100%;
}
.toogle_hide.big_sideswrap.sidebar_togle {
    display: none;
}
.icons-sidebar {
    display: flex;
}

.toggle-icon-n {
    display: none;
}
.toggle-show {
    display: block !important;
    cursor: pointer;
}
.name_menus {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.fs-16 {
    font-size: 16px;
    font-weight: 600;
    margin-top: 0;
}
.side_title {
    margin-bottom: 0;
}
.itesm_list {
    overflow-y: auto;
    height: 100%;
}
.btn-secondary:not(:disabled):not(.disabled).active, .btn-secondary:not(:disabled):not(.disabled):active, .show > .btn-secondary.dropdown-toggle {
    color: #fff !important;
    background-color: #474e9c !important;

}
.btn.btn-secondary:focus-visible{
    color: #fff !important;
    background-color: #474e9c !important;
}
.btn-secondary.focus, .btn-secondary:focus{
    color: #fff !important;
    background-color: #474e9c !important;
}
.itesm_list ul li a {
    cursor: pointer;
    font-size: 14px;
    margin: 12px 10px;
    padding: 6px;
    transition: all .3s;
    display: flex;
    align-items: center;
    background: #fff;
    border-radius: 50px;
    text-decoration: none;
    color: #474e9c;
}
.itesm_list ul li .icon-border {
    width: 26px;
    height: 26px;
    background: #efefef;
    padding: 5px;
    border-radius: 50%;
    color: #474e9c;
    display: flex;
    align-items: center;
    justify-content: center;
}
.itesm_list li {
    list-style: none;
}
.itesm_list ul {
    padding: 0;
}
.header_sidebars {
    background: #fff;
    border-bottom: 1px solid hsla(0,0%,81%,.973);
    height: 60px;
    overflow: hidden;
    padding: 0 20px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 9;
}
.name_s p {
    font-size: 20px;
    font-weight: 600;
    margin: 0;
}
.width-calc {
    width: calc(100% - 280px);
}
.h-60 {
    height: 60px;
}
.front-img img{
    width: 100%;
    max-width: 340px;
    margin: 0 auto;
    display: block;
}
.load-search h2 {
    text-align: center;
    font-size: 25px;
}
.load-search  .home-searchbar {
    position: relative;
}
.load-search .submit-btn {
    position: absolute;
    top: 115px;
    left: 50%;
    transform: translateX(-50%);
}
.load-search .home-searchbar {
    display: flex;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 5px 0px, rgba(0, 0, 0, 0.16) 0px 2px 10px 0px;
    border: 0.92px solid #e4e5e8;
    background: #fff;
    border-radius: 6px;
    max-width: 1060px;
    margin: 0 auto;
    padding: 35px 35px 40px;
    justify-content: space-between;
}
.load-search .home-searchbar .input-group {
    width: 48%;
    min-height: 57PX;
    background: #f7f7f7;
    z-index: 1;
    border-radius: 7px;
}
.mainareas {
    background: #ffffff;
    padding: 40px;
    height: calc(100vh - 72px) !important;
    overflow: auto;
}
.load-search .home-searchbar .input-group  path {
    stroke: rgb(82, 82, 82) !important;
}
.load-search .dark-btn {
    background: #3f559e;
    color: #fff;
    border: 1px solid #3f559e !important;
    width: 100%;
    padding: 8px 10px;
}
.das-box {
    border: 0.0375rem solid #c4c4c4;
    border-radius: 1rem;
    box-shadow: rgba(12, 0, 51, 0.1) 0px 0.25rem 0.5rem 0px;
    padding: 15px 20px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #474e9c08;
}

.load-search .cards_sales {
    max-width: 1060px;
    margin: 75px auto;
}
.load-search img.wallets_img {
    width: 60px;
    height: 60px;
    background: #ffffff;
    padding: 8px;
    border-radius: 50px;
    opacity: .8;
    box-shadow: rgba(27, 31, 14, 0.25) 0px -1px 55px -21px inset, rgba(0, 0, 0, 0.2) 0px 10px 28px -29px inset;
}
.bids-data {
    border: 1px solid #00000045;

    border-radius: 5px;
}

.bids-upper {
    display: flex;
    flex-wrap: wrap;
    padding: 20px;
}

.bids-middle {
    width: 30%;
    border-right: 1px solid #0000002b;
    padding-right: 3px;
}
.bids-right{
    width: 20%;
    padding-left: 30px;
}
.bids-bottom {
    margin: 0;
   border-right: 1px solid #0000002b;
    width: 50%;
    padding-left: 30px;

}
.bids-bottom ul {
    margin: 0;
    padding: 0;
}
.bids-middle h5 {
    font-size: 14px;
    color: #0000008a;
    display: flex;
}
.bids-middle h5 {
    font-size: 15px;
    color: #000;
    font-weight: 400 !important;
}
.bids-middle h5 span {
    width: 120px;
    color: #0000008a;
}
.bids-bottom ul li {
    list-style: none;
    display: flex;
    align-items: center;
    margin: 8px 0;
    font-size: 15px;
    color: #000000a1;
}
.bids-upp-m {
    background: #f2f3f499;
    padding: 9px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px dashed #00000021;
}
.bids-upp-m h5 {
    flex-direction: column;
    margin-bottom: 0;
    gap: unset;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
    display: flex;
}
.bids-upp-m h5 span {
    font-size: 14px;
    color: #0000008a;
    margin-top: 2px;
}

.bids-upp-m p svg {
    width: 35px;
    height: 35px;
}
.bids-upp-m p {
    width: 20%;
    margin: 0 auto;
    text-align: center;
}
.bids-right
 h5 {
    font-size: 15px;
    text-transform: capitalize;
    color: #e51c1c;
}
.bid-p {
    font-size: 19px;
    font-weight: 500;
}
.bids-bottom h3 {
    font-size: 18px;
    line-height: 18px;
}
.bids-bottom ul li svg {
    transform: rotate(180deg);
    color: #0000005c;
    width: 20px;
    height: 20px;
    margin-right: 7px;
}
.bids-right h5.badge.badge-primary {
    width: 138px;
    height: 38px !important;
    margin: 10px 0 0 0 !important;

}
.view-detail {
    margin: 15px 0 0 0;
}
.view-detail a,.view-detail span  {
    font-size: 13px;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.view-detail a svg{
    width: 20px;
}
.mai_data_full.dashboard_full {
    width: calc(100% - 50px);
}
.ulclasssides li a:hover{
    color: #fff !important;
}
.ulclasssides li a.active {
    color: #474e9c;
    background: #fff;
    padding: 7px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    transition: .3s ease-in;
}
.bids-top h4 {
    margin: 0;
}
.bids-top {
    display: flex;
    align-items: center;
    justify-content: space-between;

}
.ulclasssides li a.active svg{
    color: #494f9f;
}
.loc-flex {
    display: flex;
    width: 30%;
    justify-content: center;
}
.loc-flex img {
    width: 35px;
    margin: 0 13px 0 0;
}
.common-tbls.table > :not(:last-child) > :last-child > * {
    background: transparent;
    border-bottom: 0;
    padding: 0;
}
table.common-tbls {
    background: #fff;
    margin: 0;

    border: 0;
    border-bottom: 1px solid #00000040;
}
.common-tbls td{
    padding: 0;
    border: 0;
}
.tbl-new {
    border: 1px solid #00000040;
    padding: 10px;
}
.staff-inner {
    padding: 0 !important;
    margin: 0;
    display: flex;
    width: 90%;
    flex-wrap: wrap;
}
.staff-inner li {
    list-style: none;
    width: 25%;
}
.staff-action li {
    list-style: none;
}
.staff-inner li h5 {
    font-size: 14px;
}
.staff-action li h5 {
    font-size: 14px;
}
.staff-inner li p {
    margin: 0;
    font-size: 14px;
    line-height: 30px;
}
.staff-main {
    display: flex;
    width: 100%;
    padding: 10px;
    border-bottom: 3px solid #474e9c26;
}
.staff-inner li:nth-child(2) {
    width: 35%;
}
.staff-inner li:nth-child(4) {
    width: 15%;
}
.staff-listing {
    margin: 0 0 20px 0;
    box-shadow: rgba(99, 96, 99, 0.2) 0px -1px 8px -2px;
    border-radius: 5px;
    border: 1px solid #00000014;
}

ul.staff-action .dropdown.dotsbtn {
    position: unset;
    margin: 0;
    padding: 0;
}
ul.staff-action {
    width: 10%;
    padding: 0;
    margin: 0;
}
.white-head-inner  .loc-flex {
    display: flex;
    width: 50%;
    justify-content: flex-start;
}
.white-head-inner .bids-upp-m {
    background: #474e9c14;
    padding: 9px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #0000001a;
}
.white-head-inner .view-flex {
    display: flex !important;
    flex-direction: column;
}

.white-head-inner label.profileheddingcls{
    width: 100%;
    font-weight: 400 !important;
}
.edit-page-commons .white-head {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    padding: 9px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    background: #474e9c12;
}
.edit-page-commons .white-head .track-details h5 span {
    font-size: 14px !important;
    color: #0000008a;
    margin-top: 2px;
    display: block;
}
.edit-page-commons .view-flex .profiledetailscls {
    background: transparent;
    padding: 0;
    border-radius: 0;
    height: unset !important;
    font-size: 14px;
    color: #000;
    font-weight: 500 !important;
    line-break: unset;
}
.edit-page-commons .view-flex .profileheddingcls {
    font-weight: 400 !important;
    font-size: 14px !important;
    margin: 0;
}
.steps-main .rs-steps{
    width: 100% !important;

}
.steps-main  .rs-steps-item-status-finish .rs-steps-item-icon-wrapper {
    border-color: #474e9c !important;
    color: #ffffff;
    background: #474e9c !important;
}
.steps-main .rs-steps-item-status-finish .rs-steps-item-tail,.steps-main .rs-steps-item-status-finish .rs-steps-item-title::after {
    border-color: #454d99 !important;
}
.steps-main .rs-steps-vertical .rs-steps-item:not(:first-child) {
    margin-top: 0;
}
.steps-main  .rs-steps-vertical .rs-steps-item-tail {
    top: 0 !important;
    border-left-style: dashed !important;
}
.steps-main .rs-steps-item-title {
    font-size: 14px;
    color: #000000c4;
}
.steps-main .rs-steps-item-description {
    margin-top: 0px;
    font-size: 14px;
}

.arrow-center img {
    width: 25px;
    margin: 0 15px;
}
.track-details  .loc-flex img {
    background: #fff;
    padding: 5px;
    border-radius: 50px;
    box-shadow: rgba(99, 99, 99, 0.1) 0px 2px 2px 0px;
}
.white-head .track-details h5 {
    margin: 0;
    font-size: 14px;
}

.edit-page-commons .profilelist {
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: .94px;
    color: #000000e3;
}
.track-details {
    display: flex;

    flex-direction: column;
    padding: 10px;
}
.track-details .loc-flex {
    width: 100%;
    justify-content: flex-start;
    align-items: center;
}
.track-details .loc-flex span {
    display: block;
    font-size: 14px !important;
    color: #0000008a;
    margin-top: 2px;
    display: block;
}
.arrow-center img {
    transform: rotate(90deg);
}
.track-details .loc-flex img {
    background: #474e9c0f;
    padding: 12px;
    border-radius: 50px;
    box-shadow: rgba(99, 99, 99, 0.1) 0px 2px 2px 0px;
    width: 64px;
    height: 64px;
}
.track-details h5 {
    margin: 0;
    font-size: 14px;
}

// .arrow-center img {
//     width: 35px;
//     margin: 10px 20px;
//     display: flex;
//     align-items: center;
//     justify-content: center;
// }
.arrow-center {
    border-left: 1px dashed #474e9c7d;
    margin: 7px 34px;
    height: 56px;

    display: flex;

}
span.custom-toggle-btn {
    height: 100%;
    display: flex;
    // margin: 14px 0 0 0;
}

    .active .custom-toggle-btn .react-switch-bg {
        height: 20px !important;

        background: #494f9f !important;

    }
    .custom-toggle-btn .react-switch-bg {
        height: 20px !important;

    }
    .custom-toggle-btn .react-switch-handle {
        height: 13px !important;
        width: 13px !important;
        top: 3px !important;
        left: 0px !important;
    }
    .active .custom-toggle-btn .react-switch-handle {
        height: 13px !important;
        width: 13px !important;
        top: 3px !important;
        left: 0 !important;

    }

// .new-edit label{
//     background: #fff;
//     margin: -9px 0 0 19px;
//     position: absolute;
//     font-size: 12px;
//     padding: 0 4px;
// }
// .new-edit input, .new-edit select{
//     height: 44px;
// }
// .new-edit .col-md-6{
//     position: relative;
// }
.new-edit label {
    font-size: 14px;
    margin: 0 0 5px 0;
    letter-spacing: .96px;
    color: #000000a6;
}
.input-new-design {
    display: flex;
    border: 1px solid #ced4da;
    border-radius: 4px;
    height: 40px;
}
.input-icon {
    padding: 4px 16px;
    background: #474e9c12;
    color: #474e9c;

    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.input-new-design input {
    border: 0;
    padding-right: 4px;
    padding-left: 7px;
    padding-top: 6px;
    padding-bottom: 6px;
    height: 38px;
    font-size: 14px;
}
.react-tel-input .form-control {
    position: relative;
    font-size: 13px !important;
    letter-spacing: 0.01rem;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding-left: 44px !important;
    margin-left: 0;
    background: #ffffff;
    border: 1px solid #cacaca;
    border-radius: 5px;
    line-height: 25px;
    height: 38px;
    width: 394px;
    outline: none;
}
.input-new-design select {
    border: 0;
}
.new-edit .form-control:disabled,.new-edit .form-control[readonly] {
    background-color: #0000001c;
    opacity: 1;
    border-radius: 0;
}
.new-edit .white-bg-main {
    background: #fff;
    border: 1px solid #efefef;
    // border-radius: 5px;
    // box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
}
.new-edit .imagethumbWrapper img {
    width: 200px;
    border-radius: 50%;
    height: 200px;
    object-fit: cover;
    border: 1px solid #e3dada;
    padding: 4px;
    margin: 0 auto;
    display: block;
}
.new-edit .imagethumbWrapper {
    position: relative;
    width: 200px;
    height: 200px;
    margin: 26px auto;
    display: block;
}
.new-edit .upload-img-icon {
    position: absolute;
    bottom: 5px;
    right: 33px;
}
.new-edit  .upload-img-icon .btn {

    background: #474e9c;
}
.new-edit  .upload-img-icon .btn svg{
    color: #fff;
}
.new-edit .white-head {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    padding: 9px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    background: #474e9c12;
}
.new-edit .profilelist {
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: .94px;
    color: #000000e3;
}
.profile-sect-n {
    background: #f2f2f8 !important;
    margin: 0;
}
.disable-input {
    text-align: center;
}
.new-edit .disable-input .form-control:disabled{
    background-color: #474e9c;
    opacity: 1;
    border: 0;
    text-align: center;
    color: #fff;
    border-radius: 4px;
}
.page-new.paginationWrapper.main-pagination {
    justify-content: center;
}
.action-btns a {
    border: 1px solid #474e9c47;
    padding: 4px 10px;
    border-radius: 3px;
    background: #474e9c0f;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.action-btns a span i {
    font-size: 14px;
    color: #0000006b;
}
.new-width {
    width: 100px !important;
    height: 100px;
    display: block;
    margin: 0 auto 20px auto;
}
.new-width img {
    width: 100%;
    height: 100%;
}
.user-info-bx {
    text-align: center;
}
.email-text {
    font-size: 14px;
}
.user-info-bx h4 {
    margin: 10px 0 4px 0 !important;
    line-height: 20px;
    font-size: 18px;
}
.profile-img .dropdown-menu.show {
    border: none;
    cursor: pointer;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.profile-img ul li a.dropdown-item {
    color: #000000b0;
}
.profile-img ul li{
    margin: 0;
}
.profile-upper {
    background: #efefef38;
    margin: 0 0 20px 0;
    border: 1px solid #00000012;
    padding: 20px;
    border-radius: 10px;
    border-left: 2px solid #474e9c4f;
}
.portal-new {
    padding: 60px 0 !important;
}
.portal-new .profile-tabs-left {
    background: #474e9c00;

}
.notify-img img {
    width: 200px;
    margin: 30px auto;
    display: block;
}
.mainareas .no-data img {
    width: 260px;
}
.notify-img h4 {
    text-align: center;
   margin: 0;
}
.notification-main {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border: 1px solid #0000001c;
}
.notify-bottom .notification-item {

    background: transparent;

}
// .notification-main {
//     background: #474e9c12;
//     border: 1px solid #efefef;
//     box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
// }
.notify-bottom {
    background: #fff;

}
.notify-bottom .noteimg {
    min-width: 25px;
    width: 25px;
    height: 25px;
    object-fit: cover;
    border-radius: 28px;
}
.notify-bottom a {
    text-decoration: none;

}
.notify-bottom .notifications-wrapper h4 {
    margin: 0 15px 22px 0;
    color: #474e9c;
    font-size: 19px;
}
.notify-bottom .notifications-wrapper h4::after{
    content: "";
    width: 92px;
    height: 2px;
    background-color: #474e9c;
    display: block;
}
.notify-bottom .notifications-wrapper {
    overflow: auto;
    max-height: unset;
    border-bottom: 1px dashed #474e9c7a;
    margin: 0 12px;
    padding-bottom: 12px;
}
.notify-upper {
    padding: 3px 0 20px 0;
    border-bottom: 2px solid #474e9c24;
    background: #474e9c0a;
}
.notify-bottom .notifications-wrapper h4 {
    padding: 12px;
}
.staff-width li {
    width: 18%;
}
.staff-width li:first-child{
    width: 25%;
}
.staff-width li:last-child{
    width: 10%;
}
.staff-width li:nth-child(2){
    width: 28%;
}
.new-width img.uploadimage{
    margin: 0 auto;
}
.active .custom-toggle-btn .react-switch-bg {

    background: #494f9f !important;
}
.staff-listing .custom-toggle-btn .react-switch-bg {
    background: #474e9c61 !important;
}
.position-person {
    padding: 3px 20px;
    background: #474e9c1a;
    width: fit-content;
    margin: 7px auto 0 auto;
    font-size: 14px;
    border-radius: 33px;
    color: #000000a3;
}
.height-set .white-bg-main {
    background: #fff;
    border: 1px solid #efefef;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-items: stretch;
    height: 100%;
}
.btn-height {
    height: 43px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.carrier-width .staff-inner {

    width: 80%;

}
.carrier-width ul.staff-action{
    width: 20%;
}
.notification-m .notcount {
    position: absolute;
    top: -2px;
    text-align: center;
    height: 14px;
    right: -4px;
    width: 14px;
    max-width: 28px;
    background-color: #d63031;
    border-radius: 20px;
    color: white;
    border-style: solid;
    border-width: 1px;
    font-size: 9px;
    line-height: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.dark-btn.btn-width {
    width: fit-content;
    padding: 6px 16px !important;
}
.bids-upper .badge-success {
    background: #3c559b1c;
    color: #0009;
    padding: 3px 8px;
    border-radius: 3px;
}
span.badge-danger {
    background: #ff000038;
    color: #00000082;
}
.badge-primary {
    background: #474e9c1c;
    color: #0000006e;
}
.accept-user i {
    color: #3c559b !important;
}
.reject-user i {
    color: #c1711a !important;
}
.row.search-truck {
    background: #f2f2f8;
    margin: 0;
}
.search-truck img {
    width: 73px;
    margin: 0 auto;
    display: block;
    background: #fff;
    padding: 9px;
    border-radius: 50%;
}
.bids-top span {
    background: #434a941a;
    width: max-content;
    height: 35px;
    display: inline-block;
    text-align: center;
    margin: 0 0 0 10px;
    border-radius: 4px;
    color: #0000006e;
    font-size: 18px;
    line-height: 30px;
    padding: 3px 13px;
}
.border-set-dash {
    border-bottom: 1px dashed #0000005c;
    margin: 32px 0;
}
.bids-top h4{
    display: flex;
    align-items: center;
}
.rs-steps-item-icon-wrapper {
    background: #fff !important;
}
.height-set {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-items: stretch;

}
.steps-scroll {
    height: 200px;
    overflow: auto;
}
.rs-steps-item-status-process .rs-steps-item-icon-wrapper {

    color: #545461 !important;
}
.sidebar_active {
    background: #5146a0 !important;
    color: #fff !important;
}
.sidebar_active:hover {
    color: #fff !important;
}
.profile-img ul li a.dropdown-item:hover {
    color: #333030 !important;
}
.profile-img .dropdown-menu.show svg {
    width: 16px;
}
.active .custom-toggle-btn .react-switch-bg {
    background: #5146a0 !important;
}
.deactive .custom-toggle-btn .react-switch-bg {

    background: #474e9c61 !important;
}
.staff-new {
    width: 85%;
}
ul.staff-action {
    width: 15%;

}
.bids-bottom span.destspan svg {
    margin: -1px 0 0 0;
    transform: rotate(180deg);
    color: #0000005c;
    width: 20px;
    height: 20px;
    margin-right: 7px;
}
.bids-bottom .destspan {
    display: -webkit-box;
    max-width: 366px;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: 15px;
    color: #000000a6;
}
span.tableficon {
    margin: 0 0 0 10px;
}
.bids-right .text-primary {
    color: #474e9c !important;
    font-size: 14px;
    font-weight: 600;
    text-decoration: underline !important;
}
.w-max {
    width: 200px;
}
.bids-new {
    background: #fff;
}
.new-dashboard .wallets_img {
    width: 60px;
    height: 60px;
    background: #ffffff;
    padding: 8px;
    border-radius: 50px;
    opacity: .8;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.new-dashboard .sales_headings{
    flex-direction: column;
}
.das-box p.sub_heading {
    text-align: left;
    margin: 0 0 7px 0 !important;
    color: #000000b5;
}
.new-dashboard .sales_section {
    padding: 0;
}
.new-dashboard .graph_section {
    margin: 1rem 0rem;
    background: #ffffff;
    padding: 0;
    border-radius: 12px;
    height: 96%;
    border: 1px solid #0000001c;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
}
.new-dashboard  .graph_range {
    padding: 20px;
    background: #f9f9fc;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom: 1px solid #0000000d;
}
.new-dashboard  .graph_range .Amount_sales {
    font-size: 20px;
    font-weight: 600;
    line-height: 21px;
    margin-bottom: 0px !important;
}
.modal.left .modal-dialog, .modal.right .modal-dialog {
    // position: fixed;
    position: absolute;
    margin: auto;
    width: 320px;
    height: 100%;
    top: 0;
    right: 0;
    // transform: translate3d(0%, 0, 0);
}
.modal.right.fade .modal-dialog {
    right: 0;
    // transition: opacity 0.3s linear, right 0.3s ease-out;
    transition: right 0.3s ease-out;
}
.modal.left .modal-content, .modal.right .modal-content {
    height: 100%;
    overflow-y: auto;
}
.modal.left .modal-body, .modal.right .modal-body {
    padding: 15px 15px 80px;
    padding-bottom: 10px;
}
.modal {
    z-index: 1050 !important; /* Ensure the modal has a high z-index */
  }
.new-table .action_icons span, .new-table .action_icons a {
    border: 1px solid #474e9c47;
    padding: 8px 10px;
    border-radius: 3px;
    background: #474e9c0f;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 36px;
    height: 34px;
}
.new-table  .table thead th {
    background: #474e9c1f;
}
.new-table .table th,.new-table .table td {
    padding: 0.75rem;
    vertical-align: middle;
    background: #e3d9d91f;
    font-size: 13px;
}
.new-table .table {
    border: 1px solid #00000014 !important;
}
.stops-down-arrow {
    background: #474e9c26;
    padding: 6px 9px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    width: -moz-fit-content !important;
    width: fit-content !important;
    transition: .3s all ease-in;
    cursor: pointer;
}
.desc-set-m h4, .desc-set-m h3, .desc-set-m h2, .desc-set-m h1, .desc-set-m p {
    font-size: 13px;
    line-height: 19px;
}
.new-table tr{
    cursor: pointer;
    transition: .3s all ease-in;
}
.stops-down-arrow i {
    font-size: 17px;
    color: #474e9c;

}
.header-search {
    position: relative;
    width: 30%;
}
.header-search i {
    position: absolute;
    top: 50%;
    right: 0px;
    transform: translateY(-50%);
    background: #474e9c1c;
    padding: 12px 13px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    color: #00000078;
    font-size: 14px;
}
.stops-inn {
    padding: 20px;
}
td.bg-white {
    background: #fff;
}
.stops-inn .table th{
    background: #474e9c !important;
    color: #fff;
}
.stats-bottom {
    max-width: 1060px;
    margin: 0 auto;
}
.stats-bottom  .sales_section.sales_section_top,.stats-bottom  .sales_section_stats,.stats-bottom  .enterderstats {
    box-shadow: 4px 5px 0px 0px #494f9f6b !important;
    border: 1px solid #0000000a;
    background: #474e9c0a;
}
.stats-bottom p.sub_heading {
    color: #202020bf;
    text-align: center;
}
.stats-bottom h4 {
    font-size: 20px;
}
.stats-bottom .main_sales {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
}
.stats-page .home-searchbar label {
    font-weight: 500;
}
.css-1p3m7a8-multiValue {
    background-color: #474e9c24 !important;
}
.stats-page .graph_section {
    margin: 1rem 0rem;
    background: #fff;
    padding: 0;
    border-radius: 2px;
    height: 96%;
    box-shadow: 0px 0px 2px #cccccc40 !important;
    border: 1px solid #00000036;
}
.stats-page  .graph_range {
    padding: 10px;
    background: #474e9c1a;
}
.stats-page  .graph_range .Amount_sales {
    font-size: 17px;
    font-weight: 500;
    line-height: 32px;
    margin-bottom: 0px !important;
    padding: 0 10px;
}
.postings_table td {
    background: #474e9c0a;
}
.custom-dropdown .nav-tabs {
    border: 1px solid #00000047;
    padding: 1px !important;
    border-radius: 5px;
}
.custom-dropdown span.nav-link {
    margin: 0;
    color: #000000a1;

}
.custom-dropdown .nav-tabs .nav-link.active {
    color: #474e9c !important;
    background-color: #494f9f12 !important;
}
Select Filters-set .another-row .equal-width-50{
    width: unset !important;
}
.custom-dropdown .nav-tabs .nav-item {
    // margin-bottom: 1px;
}
.custom-dropdown .nav-tabs .nav-link {
    border: 0;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;

}
.new-table-set .equal-width-50 .blck-border-btn {
    height: 43px;
}
.new-table-set .btn-primary {
    font-size: 14px !important;
}
.stats-banner img {
    width: 330px;
    margin: 0 auto;
    display: block;
}
.edit-page-commons .title-head .ViewUseraa {
    font-size: 20px;
}
.new-edit .table thead th {
    background: #474e9c12;
}
.edit-page-commons .white-head {
    background: #474e9c17;
    padding: 15px 20px;
    border-bottom: 1px solid #efefef;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    color: #000000b8;
    font-weight: 400 !important;
    border-bottom: 1px solid #474e9c17;
}
.edit-page-commons .white-bg-main {
    background: #fff;
    border: 1px solid #474e9c30;
    border-radius: 5px;
}
.edit-page-commons .white-head h5 {
    margin: 0;
    font-weight: 500;
}
.black-screen {
    background: #00000052;
}
.new-table-set .black-screen .btn-primary{
    font-size: 16px !important;
}
.deletemodal h5 {
    max-width: 271px;
    margin: 0 auto;
    line-height: 30px;
    font-size: 18px !important;
}
.new-edit .imagethumbWrapper.new-p img {
    width: 100px;
    height: 100px;
    margin: 0;
}
.new-edit .imagethumbWrapper.new-p{
    width: 100px;
    height: 100px;
    margin: 0;
}
.custom-dropdown ul {

    display: flex;
}
// .css-13cymwt-control {
//     height: 43px;
// }
.main-h .btn-primary{
   width: 175px;

}
.image-centered .imagethumbWrapper img {
    width: 120px;
    height: 120px;
    margin: 0 auto;
}
.image-centered {

    border-right: 1px dashed #474e9c8c;
}
.result-set label {
    width: 100px;
    height: 39px;
    position: relative;
    margin: 0;
}
  .result-set input {
    visibility: hidden;
    width: 100px;
    height: 40px;
    position: absolute;
    left: 0;
  }
  .result-set i{
    font-size: 13px;
  }
  .btn-height i {
    font-size: 13px;
  }


.image-centered .view-flex .profiledetailscls {
    font-size: 15px !important;
    margin: 23px auto 0 auto !important;
    background: #474e9c;
    display: block;
    width: fit-content;
    text-align: center;
    padding: 4px 13px;
    color: #fff;
    height: 31px;
    border-radius: 4px;
}
.new-table .badge.badge-danger {
    background-color: #a1002724 !important;
    color: #a10027 !important;
    font-weight: 500;
    font-size: 12px;
}
.new-table .badge.badge-primary {
    font-weight: 500;
    font-size: 12px;
    background: #28a74530 !important;
    color: #00000091 !important;
}
.new-table span.badge {
    font-weight: 500;
    font-size: 12px;
    background: #444b961f;
    color: #50459d;
    width: 100px;
}

.new-price-range.tbl-shadow {
    background: #474e9c0d;
    border: 1px solid #d5d5d5 !important;
    padding: 11px 13px 7px;
    border-radius: 4px;
    width: 300px;
    height: 43px;
    display: flex;
}
.new-price-range .price-range-text {
    width: 161px;
    line-height: 20px;
}                                                                                                                                                              .new-price-range .range_price {
    line-height: 12px;
}
.origin.address {
    background: #474e9c0d;
    border: 1px solid #00000014 !important;
}
.origin.address h2 {
    background: #41488f;
    color: #fff;
    font-size: 17px;
    padding: 13px;
    margin: 0;
}
.origin.address ul {
    padding: 10px;
    margin: 0;
}
.origin.address ul li{
    list-style: none;
    display: flex;
}
.origin.address ul li h5 {
    font-size: 16px;
    margin-left: 20px;
}
.address-fields {
    margin: 0 0 20px 0;
}
.selet-drop{
    width: 100% !important;
}
.selet-drop .custom-toggle.dropdown-toggle {
    border: 0 !important;
    width: 100% !important;
}
.selet-drop .custom-toggle.dropdown-toggle::after{
    display: block !important;
}
.react-date .react-datepicker-wrapper {
    width: 100%;
}
.new-table-set .btn-primary {
    font-size: 14px !important;
}
.height-unset{
    height: unset !important;
}
.ellipses-set p {
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    max-width: 180px;
    font-size: 13px !important;
    color: #222 !important;
    font-family: "Poppins", sans-serif !important;
    line-height: 21px !important;
    height: 23px !important;
}

.ellipses-set,.ellipses-set > div, .ellipses-set > h3, .ellipses-set > h4, .ellipses-set > h5, .ellipses-set > h6, .ellipses-set > h2, .ellipses-set > h1, .ellipses-set > ul > li, .ellipses-set > ul > li > strong, .ellipses-set > ul{
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    max-width: 180px;
    list-style: none;
    padding-left: 0 !important;
    font-size: 14px;
    font-weight: 400 !important;
}
.profiledetailscls.height-unset p , .profiledetailscls.height-unset ul li {
    margin-bottom: 0px;
    font-size: 14px !important;
    color: #222 !important;

}
.profiledetailscls.height-unset ul li {
    margin: 0 0 10px 0;
}
.height-center  {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 87%;
}
 .echarts-for-react {
    width: 100% !important;

}
.download-doc img.pdfimg {
    width: 42px !important;
}
.download-doc i {
    color: #ffffff;
    font-size: 8px;
    background: #474e9c;
    padding: 5px;
    border-radius: 23px;
    position: absolute;
    right: -4px;
    bottom: 0;
}
.download-doc {
    width: 42px;
    position: relative;
    display: flex;
}
.edit-page-commons .col-lg-6.mb-3.view-flex {
    display: flex;
}
.edit-page-commons  .table{

        border: 1px solid #00000024;

}
.edit-page-commons  .stops-address  .table thead th {
    background: #474e9c;
    font-weight: 500;
    border: 0;
    color: #fff;
}
.awardic svg {
    color: #474e9c;
}
.messagep {
    color: #000000d1;
    font-size: 13px;
    line-height: 18px;
    text-align: start;
}
.notify-bottom .notification-item {
    background: #474e9c0a;
    padding: 10px;
    margin: 12px 0 !important;
    border: 1px solid #0000001a;
}
.notification-item {
    background: #cccccc3d;
    border-radius: 4px;
    margin: 5px;
    padding: 10px 0;
}
.nottime {
    color: gray;
    font-size: 10px;
    line-height: 8px;

}
.notify-left {
    width: 78%;
}
.mainareas .user_name h4 {
    font-size: 13px !important;
    margin: 0;
}
.loads-dropdown.custom-dropdown .nav-tabs .nav-item {
 width: 50%;
 text-align: center;
}
.new-profile-tb .tab-content .inputFlex-main label {
    width: 114px;

}
.new-profile-tb .edit-p .inputFlex > label {
    width: 114px;
}
::-webkit-scrollbar-thumb {
    background: #474e9ca3 !important;
}

::-webkit-scrollbar-thumb {
    background: #474e9c59 !important;
}
.approved-user .table thead th {
    background: #474e9c;
    font-weight: 500;
    border: 0;
    color: #fff !important;
}
.profile-sect-n .imagethumbWrapper .upload-img-icon .fa-times {
    position: unset;
    background-color: transparent;
    color: #ffffff;
}
.staff-react-phone .react-tel-input .flag-dropdown {
    position: absolute;
    top: 0;
    bottom: 0;
    padding: 0;
    background-color: #f5f5f5;
    border: 1px solid #cacaca;
    border-radius: 3px 0 0 3px;
    height: 38px;
}
.header-search input {

    padding-right: 49px;
}
.rs-steps-item-icon-wrapper > .rs-steps-item-icon {
    width: 100%;
    display: block;
    text-align: center;
    position: relative;
    color: #000000bd;
}
.icon-set.tableficon {
    margin: 0;
    display: flex;
    align-items: center;
}
.icon-set.tableficon i {
    padding: 5px;
    border-radius: 20px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    font-size: 10px;
    border: 1px solid #80808029;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}
span.in-transit {
    color: #06065e !important;
}
span.rs-steps-item-icon.rs-steps-item-icon-finish {
    color: #fff;
}
::-webkit-scrollbar-thumb:hover {
    background: #474e9c59 !important;
}

::-webkit-scrollbar-thumb {
    background: #474e9c59 !important;
}

::-webkit-scrollbar-thumb {
    background: #474e9c59 !important;
}
::-webkit-scrollbar-thumb {
    background: #474e9ca3 !important;
}
::-webkit-scrollbar-thumb {
    background: #474e9c59 !important;
}
::-webkit-scrollbar-thumb {
    background: #474e9c59 !important;
}
::-webkit-scrollbar-thumb {
    background: #474e9c59 !important;
}
.notify-bottom .notifications-wrapper:last-child{
    border: 0 !important;
}
.color-red {
    background: #ff395e !important;
    color: #fff !important;
}
.stops-inn span.tableficon{
    margin-left: 0 !important;
}
.loads-dropdown a{
    padding: .5rem;
}
.stops-inn .origin.address ul li p {
    width: 130px;
}
.stops-inn .origin.address ul li h5 {
    font-size: 13px;
    margin-left: 20px;
}
.new-s-modal .modal-dialog {
    width: 100% !important;
}
.stats-title {
    padding: 18px 20px;
    border: 1px solid #00000026;
    background: #474e9c0f;
    border-radius: 7px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.stats-title h2{
    font-size: 25px;
}
.start-load .react-datepicker-wrapper {
    width: 100%;
}
.width-set {
    width: 100%;
}
.width-set > div {
    width: 100%;
}
.ellipses-set {
    height: 20px;
}
.faq-sect-accordian .accordion-button {
    color: #000000c7;
    font-size: 14px;
    font-weight: 500;
    box-shadow: none;
    background: #efefef;
    border-radius: 0 !important;
}
.faq-sect-accordian .accordion-body p , .faq-sect-accordian .accordion-body ul li{
    font-size: 14px;
}
.custom-dropdown .nav-tabs .nav-link.bg-danger {
    color: #cf2525 !important;
}
div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm {
    border: 0;
    border-radius: .25em;
    background: initial;
    background-color: #494f9f !important;
    color: #fff;
    font-size: 1em;
}
.filters-selection label {
    font-size: 14px;
    margin: 0 0 4px 0;
}
.mai_data_full.dashboard_full .width-calc {
    width: calc(100% - 50px);
}
.profiledetailscls.board-name {
    width: fit-content;
}
.notify-no-data {
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.text_underline{
    font-weight: 600;
    text-decoration: underline;
    color: #474e9c;
  }

  .ulclasssides .disabled.disabled-new a.active svg{
    color: #5146a047 !important;
  }

// Media QUeries
@media only screen and (min-width:992px){
.approved-user .staff-role .view-flex .profileheddingcls {
    // min-width: 104px !important;
}
.edit-page-commons .view-flex .profileheddingcls{
    min-width: 145px !important;
}
}
@media only screen and (min-width:1299px){

    .edit-page-commons .view-flex .profileheddingcls{
        // min-width: 175px !important;
        text-wrap: nowrap;

    }
}
@media only screen and (max-width:1299px){

    .edit-page-commons  .view-flex .profileheddingcls {
        width: 140px;
        font-size: 13px !important;
    }
    // .edit-page-commons  .col-md-6.mb-3.view-flex,.edit-page-commons  .col-md-12.mb-3.view-flex {
    //     display: flex !important;
    //     flex-direction: column;
    // }
    .white-bg-main .badge {

        width: fit-content;
    }
    .new-edit .imagethumbWrapper img {
        width: 150px;

        height: 150px;

    }
    .new-edit .imagethumbWrapper {
        position: relative;
        width: 160px;
        height: 160px;
        margin: 49px auto;
        display: block;
    }
    .bids-bottom {
        padding-left: 12px;
        padding-right: 12px;
    }
    .das-box p.sub_heading{
        font-size: 13px;
    }
    .edit-page-commons .view-flex .profiledetailscls{
        font-size: 13px !important;
    }
    .view-flex {
        gap: 6px;
    }
    .bids-top h4{
        font-size: 18px;
    }
    .new-table-set .btn-primary {
        font-size: 13px !important;
    }
    .btn-height i {
        font-size: 12px;
        margin: 0  4px 0 0 !important;
    }
    .filter-b svg{
        margin: 0  4px 0 0 !important;
    }
    .bids-top span {
        font-size: 15px;
        padding: 3px 9px;
    }
}
@media only screen and (max-width:1199px){
    .bids-middle h5 {
        gap: unset;
        flex-direction: column;
    }
    .bids-middle h5 .badge{
        width: fit-content !important;
    }
    .bids-middle h5 span {
        width: 100%;
        color: #0000008a;
        margin: 0 0 4px 0;
    }
    .track-details .loc-flex img {
        padding: 7px;
        width: 47px;
        height: 47px;
    }
    .arrow-center {
        margin: 7px 23px;
    }
    .height-set {
        margin: 10px 0;
    }
    .approved-user .col-md-6.mb-3.view-flex, .approved-user .col-md-12.mb-3.view-flex {
        display: flex !important;
        flex-direction: column;
        gap: 3px;
    }
    .edit-page-commons .view-flex .profiledetailscls {

        width: 100%;
        // word-break: break-all;
word-wrap: break-word;
    }
    .edit-page-commons .image-centered .view-flex .profiledetailscls {
        width: max-content;
    }
    .flex-set-w {
        flex-direction: column;
    }
    .flex-set-w .bids-top{
        margin-bottom: 15px;
    }
    .flex-set-w .fiftyflex {
        justify-content: flex-start !important;
    }
}
@media only screen and (max-width:1024px){
    .bids-bottom {

        padding-left: 10px;
    }
    .bids-right {

        padding-left: 10px;
    }
    .staff-inner li {
        width: 50%;
        margin: 9px 0;
    }
    .staff-action li h5 {
        font-size: 14px;
        display: none;
    }
    .staff-inner {

        width: 84%;

    }
    ul.staff-action {
        width: 16%;

    }
    .staff-width li:first-child {
        width: 50%;
    }
    .staff-width li:nth-child(2) {
        width: 50%;
    }
    .staff-inner li:nth-child(4) {
        width: 50%;
    }
    .carrier-width .staff-inner {
        width: 72%;
    }
    .carrier-width ul.staff-action {
        width: 28%;
        display: flex;
        justify-content: flex-end;
    }
    .edit-page-commons .col-md-6.mb-3.view-flex, .col-md-12.mb-3.view-flex {
        display: flex !important;
        flex-direction: column;
        gap: 5px !important;
    }
    .edit-page-commons .view-flex .profileheddingcls {
        width: 100%;
    }
}
@media only screen and (max-width:991px){
    .bids-middle {
        width: 100%;
        border-right: 0;
    }
    .bids-bottom {
        width: 66%;
        padding-left: 0;
        margin-top: 25px;
    }
    .bids-right {
        width: 34%;
        padding-left: 0;
        margin-top: 25px;
    }
    .bids-middle h5 {

        flex-direction: row;
    }
    .bids-middle h5 span{
    width: 140px;
    }
    .bids-middle {
        width: 100%;
        border-right: 0;
        padding-bottom: 25px;
        border-bottom: 1px solid #00000026;
    }
    .load-search .home-searchbar .input-group {
        width: 100%;
        margin: 5px 0px;
        min-height: 48px;
    }
    .load-search .submit-btn {
        position: unset;
        transform: unset;
        margin: 10px 0 0 0;
    }
    .staff-inner li {
        width: 100%;

    }
    .staff-width li:nth-child(2) {
        width: 100%;
    }
    .staff-width li:first-child {
        width: 100%;
    }
    .staff-inner li:nth-child(4) {
        width: 100%;
    }
    .staff-inner {
        width: 78%;
    }
    .staff-new {
        width: 76%;
    }
    .home-searchbar .sales_section {
        padding: 0;
    }
    .flex-col-c {
        justify-content: flex-start !important;
    }
    .image-centered {
        border-bottom: 1px dashed #474e9c8c;
        margin: 0 0 20px 0;
        padding: 0 0 20px 0;
        border-right: 0;
    }
    .flex-col-direct {
        flex-direction: column;
        justify-content: flex-start !important;
        align-items: flex-start !important;
    }
    .flex-col-direct .custom-dropdown{
        margin-top: 15px;
    }
}
@media only screen and (max-width:767px){

    .bids-bottom {
        width: 100%;
        padding-left: 0;
        margin-top: 25px;
        border-right: 0;

    }
    .bids-right {
        width: 100%;
        padding-left: 0;
        margin-top: 25px;
        text-align: left !important;
        border-top: 1px solid #00000026;
        padding-top: 25px;
    }
   .view-detail a,.view-detail span  {
        justify-content: flex-start;
    }
    .status-p {
        justify-content: flex-start !important;
    }
    .bids-middle h5 {
        flex-direction: column;
        display: flex;
    }
    .bids-upp-m {
        flex-direction: column;
    }
    .bids-upp-m h5 {
        width: 100%;
    }
    .bids-upp-m h5 span {
        display: block;
    }
    .bids-upp-m p svg {
        width: 35px;
        height: 35px;
        transform: rotate(90deg);
    }
    .bids-upp-m p {
        width: 100%;
        margin: 14px 0;
        text-align: left;
    }
    .bids-right h5.badge.badge-primary {
        width: 138px;
        height: 38px !important;
        margin: 10px 0 0 auto;
    }
    .track-details .loc-flex img {
        padding: 7px;
        width: 50px;
        height: 50px;
    }
    .arrow-center {
        margin: 7px 24px;
        height: 56px;
    }
    .staff-inner {
        width: 75%;
    }
    ul.staff-action {
        width: 25%;
    }
    .loc-flex {
        width: 100%;
    }

    .sidebar_togle {
        position: absolute;
        top: 0;
        height: 100%;
        left: 0;
        z-index: 999999;
        transition: .3s all ease-in;
    }
    .mai_data_full {
        width: calc(100% - 50px);
        transition: .3s all ease-in;
    }
    .mainareas {
        padding: 20px;
    }
    .bids-upp-m h5 {
        font-size: 16px !important;
    }
    .header_sidebars {
        z-index: 7;
    }
    .mainareas .no-data img {
        width: 184px;
    }
    .bids-upp-m p svg {
        width: 35px;
        height: 25px;
    }
    .loc-flex img {
        width: 28px;
    }
    .bids-middle h5 {
        margin: 0 0 18px 0 !important;
    }
    .bids-bottom {.staff-new {
        width: 85%;
    }
        padding-right: 0 !important;
    }
    .bids-bottom ul li {
        font-size: 13px;
    }
    .staff-main {
        flex-direction: column;
    }
    ul.staff-action {
        width: 100%;
        margin: 20px 0 0 0;
    }
    .notify-bottom .notifications-wrapper h4 {
        font-size: 17px;
    }
    .notify-bottom .notifications-wrapper h4::after {
        width: 53px;
    }
    .header_sidebars .width-calc{
        width: 100%;
    }
    .notification-item > div {
        flex-direction: column;
        justify-content: flex-start !important;
        align-items: flex-start !important;
    }
    .notify-left {
        width: 100%;
    }
    .header-title{
    font-size: 14px !important;
    line-height: 1.2;
    }
}

